import { Component } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-home',
  templateUrl: 'home.component.html',
  styleUrls: ['home.component.scss'],
})
export class HomeComponent {
  constructor(formBuilder: FormBuilder) {
    this.formGroup = formBuilder.group({
      name: new FormControl(),
      email: new FormControl(),
      phone: new FormControl(),
      city: new FormControl(),
      message: new FormControl(),
    });
  }

  formGroup: FormGroup;
  sendingEmail = false;
  sended = false;

  onSubmit(formData) {
    if (!this.formGroup.valid) {
      return;
    }
    this.sendingEmail = true;
    const xhr = new XMLHttpRequest();
    xhr.open('POST', 'https://formspree.io/mrgokgdw');
    xhr.setRequestHeader('Accept', 'application/json');
    xhr.onreadystatechange = () => {
      if (xhr.readyState !== XMLHttpRequest.DONE) {
        return;
      }
      this.sendingEmail = false;
      if (xhr.status === 200) {
        this.formGroup.reset();
        this.sended = true;
        console.log('Success');
      } else {
        console.log('Fail');
      }
    };
    xhr.send(
      JSON.stringify({
        email: formData.email,
        message:
          'Nombre Completo: ' +
          formData.name +
          '\n' +
          'Email: ' +
          formData.email +
          '\n' +
          'Teléfono: ' +
          formData.phone +
          '\n' +
          'Ciudad: ' +
          formData.city +
          '\n' +
          'Mensaje: ' +
          formData.message,
      })
    );
  }
}
