import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
  NgcCookieConsentService,
  NgcStatusChangeEvent
} from 'ngx-cookieconsent';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.sass'],
})
export class AppComponent implements OnInit, OnDestroy {
  private statusChangeSubscription: Subscription;

  constructor(
    private ccService: NgcCookieConsentService,
    private router: Router
  ) {}

  ngOnInit() {
    this.statusChangeSubscription = this.ccService.statusChange$.subscribe(
      (event: NgcStatusChangeEvent) => {
        if (event.status === 'deny') {
          this.router.navigate(['/cookies']);
          this.ccService.clearStatus();
        }
      }
    );
  }

  ngOnDestroy() {
    this.statusChangeSubscription.unsubscribe();
  }
}
