<!-- START SECTION PRIVACY -->
<section id="start" class="section_banner section_laky_start_1">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-10 res_md_mt_50 res_sm_mt_20 pt-lg-30">
        <div class="divider small_divider d-lg-block"></div>
        <div class="WordSection1">
          <p
            class="MsoNormal"
            align="center"
            style="
              margin-top: 0in;
              margin-right: 14.2pt;
              margin-bottom: 0in;
              margin-left: 14.2pt;
              margin-bottom: 0.0001pt;
              text-align: center;
              line-height: 12pt;
              background: transparent;
            "
          >
            <b
              ><u
                ><span
                  lang="ES"
                  style="
                    font-size: 10pt;
                    font-family: 'Montserrat';
                    color: #8ab0a1;
                    text-transform: uppercase;
                  "
                  >TÉRMINOS Y CONDICIONES DE USO DE LA APLICACIÓN</span
                ></u
              ></b
            >
          </p>

          <p
            class="MsoNormal"
            style="
              margin-top: 0in;
              margin-right: 14.2pt;
              margin-bottom: 0in;
              margin-left: 14.2pt;
              margin-bottom: 0.0001pt;
              text-align: justify;
              line-height: 12pt;
              background: transparent;
              vertical-align: baseline;
            "
          >
            <span
              lang="ES"
              style="font-size: 10pt; font-family: 'Calibri Light', sans-serif"
              >&nbsp;</span
            >
          </p>

          <p
            class="MsoNormal"
            style="
              margin-top: 0in;
              margin-right: 14.2pt;
              margin-bottom: 0in;
              margin-left: 14.2pt;
              margin-bottom: 0.0001pt;
              text-align: justify;
              line-height: 12pt;
              background: transparent;
              vertical-align: baseline;
            "
          >
            <span
              lang="ES"
              style="
                font-size: 10pt;
                font-family: 'Montserrat';
                color: #8ab0a1;
              "
              >Estos Términos y Condiciones regulan la descarga, acceso y
              utilización de la aplicación móvil&nbsp;(en adelante, la
              “APLICACIÓN”), que READY2PARK, S.L. pone a disposición de los
              USUARIOS de la Aplicación (tanto rol Propietario como rol
              Preferente). </span
            ><span
              lang="ES"
              style="
                font-size: 10pt;
                font-family: 'Montserrat';
                color: #8ab0a1;
              "
              >Las presentes Condiciones Generales de contratación, (en lo
              sucesivo, “Condiciones de Contratación”) regulan expresamente las
              relaciones surgidas entre </span
            ><span
              lang="ES"
              style="
                font-size: 10pt;
                font-family: 'Montserrat';
                color: #8ab0a1;
              "
              >READY2PARK, S.L.</span
            ><span
              lang="ES"
              style="
                font-size: 10pt;
                font-family: 'Montserrat';
                color: #8ab0a1;
              "
              >, titular de la aplicación informática para dispositivos móviles
              para la apertura de accesos sin llave denominada LAKY, con
              domicilio en Ronda de Segovia 12, 10C, de Madrid, inscrita en el
              registro mercantil de Madrid con dirección de correo electrónico
              albertorodriguez@laky.es, y aquellos terceros que contraten los
              servicios de gestión de movilidad que esta compañía ofrezca a tal
              efecto.  El redactado de las CONDICIONES GENERALES ha sido
              confeccionado con la finalidad de cumplir escrupulosamente con la
              legislación vigente, siendo en todo caso aplicables las normas de
              carácter imperativo previstas en la Ley 7/1998, de 13 de abril,
              sobre Condiciones Generales de la Contratación (LCGC); el Real
              Decreto Legislativo 1/2007, de 16 de noviembre, por el que se
              aprueba el Texto Refundido de la Ley General de Defensa de los
              Consumidores y USUARIOs; la Ley 34/2002, de 11 de julio, de
              Servicios de la Sociedad de la Información y Comercio Electrónico
              (LSSICE) y cuantas otras disposiciones puedan ser de aplicación. </span
            ><span
              lang="ES"
              style="
                font-size: 10pt;
                font-family: 'Montserrat';
                color: #8ab0a1;
              "
              >Esta versión de la APLICACIÓN está disponible de forma
              gratuita.</span
            >
          </p>

          <p
            class="MsoNormal"
            style="
              margin-top: 0in;
              margin-right: 14.2pt;
              margin-bottom: 0in;
              margin-left: 14.2pt;
              margin-bottom: 0.0001pt;
              text-align: justify;
              line-height: 12pt;
              background: transparent;
              vertical-align: baseline;
            "
          >
            <span
              lang="ES"
              style="
                font-size: 10pt;
                font-family: 'Montserrat';
                color: #8ab0a1;
              "
              >La descarga y la utilización de la aplicación al menos en una
              ocasión mediante la introducción de USUARIO y password atribuyen
              la condición de USUARIO e implica la lectura, entendimiento y
              aceptación, sin reserva, de todos los términos recogidos en las
              CONDICIONES GENERALES y en la POLÍTICA DE PRIVACIDAD.&nbsp;En caso
              de que el USUARIO no esté de acuerdo con lo establecido en las
              CONDICIONES GENERALES o en la POLÍTICA DE PRIVACIDAD, deberá
              abstenerse de descargar y utilizarla.</span
            >
          </p>

          <p
            class="MsoNormal"
            style="
              margin-top: 0in;
              margin-right: 14.2pt;
              margin-bottom: 0in;
              margin-left: 14.2pt;
              margin-bottom: 0.0001pt;
              text-align: justify;
              line-height: 12pt;
              background: transparent;
            "
          ><br>
            <b
              ><span
                lang="ES"
                style="
                  font-size: 10pt;
                  font-family: 'Montserrat';
                  color: #8ab0a1;
                  text-transform: uppercase;
                "
                >1. DEFINICIÓN.
              </span></b
            ><span
              lang="ES"
              style="
                font-size: 10pt;
                font-family: 'Montserrat';
                color: #8ab0a1;
              "
              >Se entiende por CLIENTE, USUARIO o USUARIO REGISTRADO a la
              persona física, y en relación con el Servicio “cuenta empresa” a
              la persona física o jurídica, que previa aceptación de las
              presentes Condiciones se adhiere al servicio mediante la descarga
              de la aplicación y, en su caso, hace uso al menos en una ocasión
              de los servicios de accesibilidad ofrecidos.</span
            >
          </p>

          <p
            class="MsoNormal"
            style="
              margin-top: 0in;
              margin-right: 14.2pt;
              margin-bottom: 0in;
              margin-left: 14.2pt;
              margin-bottom: 0.0001pt;
              text-align: justify;
              line-height: 12pt;
              background: transparent;
            "
          >
            <span
              lang="ES"
              style="
                font-size: 10pt;
                font-family: 'Montserrat';
                color: #8ab0a1;
              "
              >Servicio: Se define como tal cada uno de los servicios
              proporcionados por </span
            ><span
              lang="ES"
              style="
                font-size: 10pt;
                font-family: 'Montserrat';
                color: #8ab0a1;
              "
              >READY2PARK, S.L.</span
            ><span
              lang="ES"
              style="
                font-size: 10pt;
                font-family: 'Montserrat';
                color: #8ab0a1;
              "
            >
              mediante el uso de la aplicación LAKY en smartphones, previo pago
              de una tarifa (ofertada a través de la APLICACIÓN) para cada
              USUARIO con Rol Propietario, en adelante SERVICIO. Cualquier
              variación en el coste del servicio deberá ser comunicada al
              USUARIO con Rol Propietario con carácter previo, a fin de que
              pueda manifestar su consentimiento u oposición. Los USUARIOS con
              Rol Preferente no deberán pagar ninguna cantidad para usar los
              servicios LAKY puesto que quedan autorizados por los USUARIOS con
              Rol Propietarios que los incluyan en su tarifa.</span
            >
          </p>

          <p
            class="MsoNormal"
            style="
              margin-top: 0in;
              margin-right: 14.2pt;
              margin-bottom: 0in;
              margin-left: 14.2pt;
              margin-bottom: 0.0001pt;
              text-align: justify;
              line-height: 12pt;
              background: transparent;
            "
          >
            <span
              lang="ES"
              style="
                font-size: 10pt;
                font-family: 'Montserrat';
                color: #8ab0a1;
              "
              >LAKY: Software implementado que permite gestionar los servicios
              de accesibilidad prestados a través de su instalación en
              smarthphones mediante aplicaciones nativas en los móviles iOS y
              Android.
            </span>
          </p>

          <p
            class="MsoNormal"
            style="
              margin-top: 0in;
              margin-right: 14.2pt;
              margin-bottom: 0in;
              margin-left: 14.2pt;
              margin-bottom: 0.0001pt;
              text-align: justify;
              line-height: 12pt;
              background: transparent;
            "
          >
          <br>
            <b
              ><span
                lang="ES"
                style="
                  font-size: 10pt;
                  font-family: 'Montserrat';
                  color: #8ab0a1;
                "
                >2.- OBJETO.
              </span></b
            ><span
              lang="ES"
              style="
                font-size: 10pt;
                font-family: 'Montserrat';
                color: #8ab0a1;
              "
              >La aplicación informática denominada LAKY, cuya contratación se
              ofrece, ha sido ideada y creada para prestar toda una serie de
              prestaciones (de aquí en adelante, los SERVICIOS) dirigidas a
              gestionar los servicios de accesibilidad del USUARIO con rol
              Propietario (y/o los USUARIOS con Rol preferente que un
              Propietario designe) para el acceso y salida a las las zonas
              comunes y/o garajes de un edificio en régimen de propiedad
              horizontal mediante la utilización de un Smartphone.
            </span>
          </p>

          <p
            class="MsoNormal"
            style="
              margin-top: 0in;
              margin-right: 14.2pt;
              margin-bottom: 0in;
              margin-left: 14.2pt;
              margin-bottom: 0.0001pt;
              text-align: justify;
              line-height: 12pt;
              background: transparent;
            "
          >
            <span
              lang="ES"
              style="
                font-size: 10pt;
                font-family: 'Montserrat';
                color: #8ab0a1;
              "
              >Los Servicios se prestan al CLIENTE en calidad de CLIENTE final,
              para su uso exclusivo mediante la utilización de un USUARIO y
              password personal e intransferible, pudiendo utilizar varias
              cuentas de acceso limitadas en función del paquete de servicios. </span
            ><span
              lang="ES"
              style="
                font-size: 10pt;
                font-family: 'Montserrat';
                color: #8ab0a1;
              "
              >Funciona en un teléfono móvil Android o iOS previamente
              instalado.</span
            >
          </p>

          <p
            class="MsoNormal"
            style="
              margin-top: 0in;
              margin-right: 14.2pt;
              margin-bottom: 0in;
              margin-left: 14.2pt;
              margin-bottom: 0.0001pt;
              text-align: justify;
              line-height: 12pt;
              background: transparent;
            "
          >
            <span
              lang="ES"
              style="
                font-size: 10pt;
                font-family: 'Montserrat';
                color: #8ab0a1;
              "
              >Las características de los Servicios podrán verse modificadas por </span
            ><span
              lang="ES"
              style="
                font-size: 10pt;
                font-family: 'Montserrat';
                color: #8ab0a1;
              "
              >READY2PARK, S.L. </span
            ><span
              lang="ES"
              style="
                font-size: 10pt;
                font-family: 'Montserrat';
                color: #8ab0a1;
              "
              >con la finalidad de adaptarlos a la evolución técnica, así como
              cualquier mejora técnica o no, que a juicio exclusivo de </span
            ><span
              lang="ES"
              style="
                font-size: 10pt;
                font-family: 'Montserrat';
                color: #8ab0a1;
              "
              >READY2PARK, S.L.</span
            ><span
              lang="ES"
              style="
                font-size: 10pt;
                font-family: 'Montserrat';
                color: #8ab0a1;
              "
            >
              permita incorporar un mayor número de prestaciones a los
              Servicios.</span
            >
          </p>

          <p
            class="MsoNormal"
            style="
              margin-top: 0in;
              margin-right: 14.2pt;
              margin-bottom: 0in;
              margin-left: 14.2pt;
              margin-bottom: 0.0001pt;
              text-align: justify;
              line-height: 12pt;
              background: transparent;
            "
          ><br>
            <b
              ><span
                lang="ES"
                style="
                  font-size: 10pt;
                  font-family: 'Montserrat';
                  color: #8ab0a1;
                "
                >3. REQUISITOS DE USO.
              </span></b
            ><span
              lang="ES"
              style="
                font-size: 10pt;
                font-family: 'Montserrat';
                color: #8ab0a1;
              "
              >El USUARIO deberá contar con un dispositivo móvil inteligente
              (Smartphone) con sistema operativo Android o IOS, cualquiera de
              estos con acceso a internet, ambos seguros y
              confiables.&nbsp;READY2PARK, SL, no será responsable por la
              seguridad de los equipos Smartphone propiedad de los USUARIO
              utilizados para el acceso al canal, ni por la disponibilidad del
              servicio en los dispositivos en los cuales se descargue la
              aplicación. En la forma permitida por la ley, los materiales de la
              aplicación se suministran sin garantía de ningún género, expresa o
              implícita, incluyendo sin limitación las garantías de calidad
              satisfactoria, comerciabilidad, adecuación para un fin particular
              o no infracción, por tanto,&nbsp;</span
            ><span
              lang="ES"
              style="
                font-size: 10pt;
                font-family: 'Montserrat';
                color: #8ab0a1;
              "
              >READY2PARK</span
            ><span
              lang="ES"
              style="
                font-size: 10pt;
                font-family: 'Montserrat';
                color: #8ab0a1;
              "
            >
              SL&nbsp;no garantiza el funcionamiento adecuado en los distintos
              sistemas operativos o dispositivos en los cuales se haga uso de la
              aplicación.</span
            >
          </p>

          <p
            class="MsoNormal"
            style="
              margin-top: 0in;
              margin-right: 14.2pt;
              margin-bottom: 0in;
              margin-left: 14.2pt;
              margin-bottom: 0.0001pt;
              text-align: justify;
              line-height: 12pt;
              background: transparent;
            "
          ><br>
            <b
              ><span
                lang="ES"
                style="
                  font-size: 10pt;
                  font-family: 'Montserrat';
                  color: #8ab0a1;
                "
                >4. RESPONSABILIDADES.</span
              ></b
            ><span
              lang="ES"
              style="
                font-size: 10pt;
                font-family: 'Montserrat';
                color: #8ab0a1;
              "
            >
              El titular de la aplicación procurará garantizar su
              disponibilidad, continuidad o buen funcionamiento. En todo caso
              podrá bloquear, interrumpir o restringir el acceso a esta cuando
              lo considere necesario para el mejoramiento de la aplicación o por
              dada de baja de la misma. Cualquier uso fraudulento que se realice
              de la aplicación será responsabilidad de la persona que lo
              realice, sin mayor limitación. Se recomienda al USUARIO  tomar
              medidas adecuadas y actuar diligentemente al momento de acceder a
              la aplicación, como, por ejemplo, contar con programas de
              protección, antivirus, para manejo de malware, spyware y
              herramientas similares. </span
            ><span
              lang="ES"
              style="
                font-size: 10pt;
                font-family: 'Montserrat';
                color: #8ab0a1;
              "
              >READY2PARK, S.L.</span
            ><span
              lang="ES"
              style="
                font-size: 10pt;
                font-family: 'Montserrat';
                color: #8ab0a1;
              "
              >&nbsp;no será responsable por: a) Fuerza mayor o caso fortuito;
              b) Por la pérdida, extravío o hurto de su dispositivo móvil que
              implique el acceso de terceros a la aplicación móvil; c) Por
              errores en la digitación o accesos por parte del cliente; d) Por
              los perjuicios, lucro cesante, daño emergente, morales, y en
              general sumas a cargo del titular de la aplicación, por los
              retrasos, no procesamiento de información o suspensión del
              servicio del operador móvil o daños en los dispositivos móviles.
              E) por los daños que puedas ocurrir por el uso fraudulento de la
              aplicación.</span
            >
          </p>

          <p
            class="MsoNormal"
            style="
              margin-top: 0in;
              margin-right: 14.2pt;
              margin-bottom: 0in;
              margin-left: 14.2pt;
              margin-bottom: 0.0001pt;
              text-align: justify;
              line-height: 12pt;
              background: transparent;
            "
          ><br>
            <b
              ><span
                lang="ES"
                style="
                  font-size: 10pt;
                  font-family: 'Montserrat';
                  color: #8ab0a1;
                "
                >5.</span
              ></b
            ><span
              lang="ES"
              style="
                font-size: 10pt;
                font-family: 'Montserrat';
                color: #8ab0a1;
              "
            >
              <b>PROCEDIMIENTO DE CONTRATACIÓN. </b>Únicamente podrá ser
              realizado en el idioma castellano. En caso de que pudiera llevarse
              a cabo en otro idioma será indicado antes de iniciar el
              procedimiento de contratación. Para poder usar la Aplicación como
              herramienta de acceso, el USUARIO deberá disponer de un teléfono
              móvil de su propiedad compatible con los requisitos técnicos de la
              Aplicación. En ningún caso </span
            ><span
              lang="ES"
              style="
                font-size: 10pt;
                font-family: 'Montserrat';
                color: #8ab0a1;
              "
              >READY2PARK, S.L.</span
            ><span
              lang="ES"
              style="
                font-size: 10pt;
                font-family: 'Montserrat';
                color: #8ab0a1;
              "
            >
              será responsable, en medida alguna, de los daños o mal
              funcionamiento provocado por la descarga, instalación o uso de la
              Aplicación o App y/o falta de actualización de la misma por parte
              del cliente.  Durante el procedimiento de registro el cliente
              introducirá el USUARIO y password facilitado para acceder a las
              funciones de la aplicación. Para la activación de la cuenta el
              cliente proporcionará de manera libre y voluntaria los datos
              personales que le serán requeridos. El registro en los SERVICIOS
              no se completará hasta que </span
            ><span
              lang="ES"
              style="
                font-size: 10pt;
                font-family: 'Montserrat';
                color: #8ab0a1;
              "
              >READY2PARK, S.L.</span
            ><span
              lang="ES"
              style="
                font-size: 10pt;
                font-family: 'Montserrat';
                color: #8ab0a1;
              "
            >
              confirme la aceptación de tal registro y/o no complete las
              comprobaciones previas que se explicitan en las presentes
              Condiciones. </span
            ><span
              lang="ES"
              style="
                font-size: 10pt;
                font-family: 'Montserrat';
                color: #8ab0a1;
              "
              >READY2PARK, S.L. </span
            ><span
              lang="ES"
              style="
                font-size: 10pt;
                font-family: 'Montserrat';
                color: #8ab0a1;
              "
              > confirmará por medios electrónicos (correo electrónico y/o SMS)
              que el registro ha sido aceptado y que por tanto se acepta la
              adhesión del USUARIO al Servicio. En todo caso, </span
            ><span
              lang="ES"
              style="
                font-size: 10pt;
                font-family: 'Montserrat';
                color: #8ab0a1;
              "
              >READY2PARK, S.L.</span
            ><span
              lang="ES"
              style="
                font-size: 10pt;
                font-family: 'Montserrat';
                color: #8ab0a1;
              "
            >
              sólo aceptará el registro de aquellos solicitantes que cumplan con
              las condiciones establecidas con carácter previo. El está obligado
              a mantener su password en estricta confidencialidad y a proteger
              la información de cualquier acceso por terceros. El USUARIO
              responderá de los daños y perjuicios que puedan ocasionarse por la
              pérdida de las herramientas de acceso. Teniendo en cuenta el
              estado de las herramientas técnicas, la prestación del Servicio
              puede quedar sujeta a restricciones o inexactitudes que quedan
              fuera del control de </span
            ><span
              lang="ES"
              style="
                font-size: 10pt;
                font-family: 'Montserrat';
                color: #8ab0a1;
              "
              >READY2PARK, S.L. </span
            ><span
              lang="ES"
              style="
                font-size: 10pt;
                font-family: 'Montserrat';
                color: #8ab0a1;
              "
              > Lo anterior se aplica en concreto a la disponibilidad de
              servicios de datos móviles que faciliten los operadores de redes
              móviles, la red móvil, la conectividad bluetooth los servicios de
              posicionamiento a través del sistema de navegación global por
              satélite y el acceso a Internet, todas las cuales dependen de las
              capacidades y características del teléfono móvil o smartphone del
              USUARIO y/o de los proveedores de acceso a internet contratados
              por éste. La falta de disponibilidad de la red móvil puede, en
              casos concretos, provocar que determinados servicios no estén
              disponibles por no funcionar la necesaria transmisión de datos.
              Asimismo, estos servicios pueden verse afectados por
              interferencias atmosféricas, por las condiciones u obstáculos
              topográficos. Lo mismo ocurre con los servicios de posicionamiento
              basados en el sistema de navegación global por satélite. Además,
              el uso de internet puede verse sujeto a restricciones adicionales.
              Por otro lado, pueden darse déficits temporales de capacidad como
              consecuencia de picos de carga en los servicios, en las redes
              móviles o de líneas fijas o en internet. El USUARIO, introducirá
              su nombre de USUARIO y password previamente facilitados por </span
            ><span
              lang="ES"
              style="
                font-size: 10pt;
                font-family: 'Montserrat';
                color: #8ab0a1;
              "
              >READY2PARK, S.L.</span
            ><span
              lang="ES"
              style="
                font-size: 10pt;
                font-family: 'Montserrat';
                color: #8ab0a1;
              "
              >, comprometiéndose a hacer uso diligente de los mismos, y no
              ponerlos a disposición de terceros, así como a comunicar
              a&nbsp;</span
            ><span
              lang="ES"
              style="
                font-size: 10pt;
                font-family: 'Montserrat';
                color: #8ab0a1;
              "
              >READY2PARK, S.L.</span
            ><span
              lang="ES"
              style="
                font-size: 10pt;
                font-family: 'Montserrat';
                color: #8ab0a1;
              "
              >&nbsp;la pérdida o robo de los mismos o del posible acceso por un
              tercero no autorizado, de tal forma que éste proceda al bloqueo
              inmediato. Todas las cuentas autorizadas por un USUARIO soportarán
              las mismas obligaciones. READY2PARK SL, se reserva en cualquier
              momento y sin necesidad de informar al USUARIO del&nbsp;derecho a
              suspender o revocar el registro realizado por el mismo, debido al
              uso de mala fe. Una vez ha sido creada la cuenta de USUARIO, se
              informa que de conformidad con lo exigido por el art. 27 de la Ley
              34/2002 de Servicios de la Sociedad de la Información y del
              Comercio Electrónico, el procedimiento de contratación seguirá los
              siguientes pasos: En el modelo de contratación de los servicios
              ofertados por&nbsp;</span
            ><span
              lang="ES"
              style="
                font-size: 10pt;
                font-family: 'Montserrat';
                color: #8ab0a1;
              "
              >READY2PARK, S.L.</span
            ><span
              lang="ES"
              style="
                font-size: 10pt;
                font-family: 'Montserrat';
                color: #8ab0a1;
              "
              >, en el supuesto en que el USUARIO se registre con USUARIO y
              password, debe de seguir las indicaciones existentes en la propia
              aplicación. A continuación, y para su uso el USUARIO con Rol
              PROPIETARIO deberá introducir los datos bancarios o la tarjeta de
              pago; y, una vez introducidos, habrá finalizado el proceso de
              contratación. Una vez finalizado dicho proceso, podrá realizar el
              uso de la aplicación. La validación de la cuenta de USUARIO,
              supone su aceptación expresa de estas condiciones generales de
              contratación, como parte de la celebración del contrato.
              Finalizado el proceso de contratación, el prestador informará al
              USUARIO con rol PROPIETARIO, una vez finalizado el procedimiento
              de contratación<s>, vía correo electrónico </s>respecto a todas
              las características, del servicio contratado.
            </span>
          </p>

          <p
            class="MsoNormal"
            style="
              margin-top: 0in;
              margin-right: 14.2pt;
              margin-bottom: 0in;
              margin-left: 14.2pt;
              margin-bottom: 0.0001pt;
              text-align: justify;
              line-height: 12pt;
              background: transparent;
            "
          >
            <span
              lang="ES"
              style="
                font-size: 10pt;
                font-family: 'Montserrat';
                color: #8ab0a1;
              "
              >&nbsp;</span
            >
          </p>

          <p
            class="MsoNormal"
            style="
              margin-top: 0in;
              margin-right: 14.2pt;
              margin-bottom: 0in;
              margin-left: 14.2pt;
              margin-bottom: 0.0001pt;
              text-align: justify;
              line-height: 12pt;
              background: transparent;
            "
          ><br>
            <b
              ><span
                lang="ES"
                style="
                  font-size: 10pt;
                  font-family: 'Montserrat';
                  color: #8ab0a1;
                "
                >6. VERSIÓN, UTILIDADES Y PRECIO.
              </span></b
            ><span
              lang="ES"
              style="
                font-size: 10pt;
                font-family: 'Montserrat';
                color: #8ab0a1;
              "
              >El coste del servicio de gestión de accesibilidad para entrada y
              salida a accesos peatonales y/o garajes de la comunidad de
              propietarios en régimen de propiedad horizontal se fijará en
              función de la utilización de la misma, y de acuerdo a los
              distintos productos utilizados por </span
            ><span
              lang="ES"
              style="
                font-size: 10pt;
                font-family: 'Montserrat';
                color: #8ab0a1;
              "
              >READY2PARK, S.L.</span
            ><span
              lang="ES"
              style="
                font-size: 10pt;
                font-family: 'Montserrat';
                color: #8ab0a1;
              "
              >, y los distintos perfiles de USUARIO con Rol PROPIETARIO que se
              creen. Las diferentes tarifas ofertadas estarán disponibles para
              conocimiento y valoración de los USUARIOS con Rol PROPIETARIO a
              través de la APLICACIÓN antes de proceder a su contratación.
              Constarán de una cuota anual y un número de llaves o accesos según
              la tarifa seleccionada por el USUARIO con Rol PROPIETARIO. Los
              USUARIOS con Rol PREFERENTE harán uso del acceso/s a los que le
              haya autorizado el USUARIO con Rol PROPIETARIO a través del cual
              ha sido autorizado.
            </span>
          </p>

          <p
            class="MsoNormal"
            style="
              margin-top: 0in;
              margin-right: 14.2pt;
              margin-bottom: 0in;
              margin-left: 0in;
              margin-bottom: 0.0001pt;
              text-align: justify;
              line-height: 12pt;
              background: transparent;
            "
          >
            <span
              lang="ES"
              style="
                font-size: 10pt;
                font-family: 'Montserrat';
                color: #8ab0a1;
              "
              >&nbsp;</span
            >
          </p>

          <p
            class="MsoNormal"
            style="
              margin-top: 0in;
              margin-right: 14.2pt;
              margin-bottom: 0in;
              margin-left: 14.2pt;
              margin-bottom: 0.0001pt;
              text-align: justify;
              line-height: 12pt;
              background: transparent;
            "
          >
            <span
              lang="ES"
              style="
                font-size: 10pt;
                font-family: 'Montserrat';
                color: #8ab0a1;
              "
              >El acceso a productos extra o perfiles superiores vendrá
              determinado por la opción del propietario. Dentro del paquete se
              garantiza el funcionamiento de la aplicación durante el plazo
              mínimo de un año en las condiciones contratadas.</span
            >
          </p>

          <p
            class="MsoNormal"
            style="
              margin-top: 0in;
              margin-right: 14.2pt;
              margin-bottom: 0in;
              margin-left: 14.2pt;
              margin-bottom: 0.0001pt;
              text-align: justify;
              line-height: 12pt;
              background: transparent;
            "
          >
            <span
              lang="ES"
              style="font-size: 10pt; font-family: 'Calibri Light', sans-serif"
              >&nbsp;</span
            >
          </p>

          <p
            class="MsoNormal"
            style="
              margin-top: 0in;
              margin-right: 14.2pt;
              margin-bottom: 0in;
              margin-left: 14.2pt;
              margin-bottom: 0.0001pt;
              text-align: justify;
              line-height: 12pt;
              background: transparent;
            "
          ><br>
            <b
              ><span
                lang="ES"
                style="
                  font-size: 10pt;
                  font-family: 'Montserrat';
                  color: #8ab0a1;
                "
                >7. MEDIO DE PAGO Y AUTORIZACIÓN DE CARGO.
              </span></b
            ><span
              lang="ES"
              style="
                font-size: 10pt;
                font-family: 'Montserrat';
                color: #8ab0a1;
              "
              >El USUARIO con Rol PROPIETARIO debe facilitar un medio de pago
              (tarjeta de crédito/débito o similar) en el que se realizará el
              cargo de la cuota de alta y, previa validación del cobro, se le
              habilitará al USUARIO con Rol PROPIETARIO y los USUARIOS con Rol
              PREFERENTE autorizados por él mismo el acceso al servicio. Una vez
              establecida la tarjeta de débito/crédito como medio de pago ésta
              podrá modificarse por otra con los mismos requisitos anteriores,
              pero no eliminarse, ya que se constituye como medio de garantía de
              pago frente a pagos de servicios que puedan llegar en diferido. En
              el caso de que así lo establezca el USUARIO con Rol PROPIETARIO en
              las opciones de configuración, y, en todo caso, cuando se haya
              contratado un Servicio y no haya saldo suficiente en el monedero,
              se podrán realizar recargas automáticas por los importes
              establecidos por el USUARIO con Rol PROPIETARIO o determinados en
              las condiciones específicas del Servicio. El USUARIO con Rol
              PROPIETARIO autoriza a </span
            ><span
              lang="ES"
              style="
                font-size: 10pt;
                font-family: 'Montserrat';
                color: #8ab0a1;
              "
              >READY2PARK</span
            ><span
              lang="ES"
              style="
                font-size: 10pt;
                font-family: 'Montserrat';
                color: #8ab0a1;
              "
            >
              SL a cargar en el medio de pago designado las cantidades derivadas
              de la utilización del Servicio.</span
            >
          </p>

          <p
            class="MsoNormal"
            style="
              margin-top: 0in;
              margin-right: 14.2pt;
              margin-bottom: 0in;
              margin-left: 14.2pt;
              margin-bottom: 0.0001pt;
              text-align: justify;
              line-height: 12pt;
              background: transparent;
            "
          ><br>
            <b
              ><span
                lang="ES"
                style="
                  font-size: 10pt;
                  font-family: 'Montserrat';
                  color: #8ab0a1;
                "
                >8. EJECUCIÓN &nbsp;DEL SERVICIO.
              </span></b
            ><span
              lang="ES"
              style="
                font-size: 10pt;
                font-family: 'Montserrat';
                color: #8ab0a1;
              "
              >Tras proceder al pago del servicio seleccionado, el registro en
              los servicios no se completará hasta que READY2PARK SL confirme la
              su aceptación y/o no complete las comprobaciones previas que se
              explicitan en las presentes Condiciones, momento a partir del cual
              estará disponible para el USUARIO con Rol PROPIETARIO, teniendo en
              cuenta las características del mismo. Para la activación del
              servicio, es necesario que el USUARIO con Rol PROPIETARIO acepte
              de manera expresa, tanto las presentes condiciones generales de
              contratación, como las políticas de privacidad y de cookies, así
              como las condiciones de uso.</span
            >
          </p>

          <p
            class="MsoNormal"
            style="
              margin-top: 0in;
              margin-right: 14.2pt;
              margin-bottom: 0in;
              margin-left: 14.2pt;
              margin-bottom: 0.0001pt;
              text-align: justify;
              line-height: 12pt;
              background: transparent;
            "
          ><br>
            <b
              ><span
                lang="ES"
                style="
                  font-size: 10pt;
                  font-family: 'Montserrat';
                  color: #8ab0a1;
                "
                >9.- ACCESO FRAUDULENTO A CUENTA.
              </span></b
            ><span
              lang="ES"
              style="
                font-size: 10pt;
                font-family: 'Montserrat';
                color: #8ab0a1;
              "
              >En caso de pérdida de acceso a la cuenta y/ o sospecho de acceso
              fraudulento el USUARIO deberá comunicarlo en el plazo máximo de 48
              horas al siguiente correo electrónico info@laky.es, el cual
              procederá inmediatamente a su bloqueo de forma inmediata. Cuando
              disponga de nuevo terminal se le facilitará nuevo USUARIO y
              password con el que podrá seguir utilizando la aplicación de
              gestión de movilidad.
            </span>
          </p>

          <p
            class="MsoNormal"
            style="
              margin-top: 0in;
              margin-right: 14.2pt;
              margin-bottom: 0in;
              margin-left: 14.2pt;
              margin-bottom: 0.0001pt;
              text-align: justify;
              line-height: 12pt;
              background: transparent;
            "
          ><br>
            <b
              ><span
                lang="ES"
                style="
                  font-size: 10pt;
                  font-family: 'Montserrat';
                  color: #8ab0a1;
                "
                >10.</span
              ></b
            ><span
              lang="ES"
              style="
                font-size: 10pt;
                font-family: 'Montserrat';
                color: #8ab0a1;
              "
            >
            </span
            ><b
              ><span
                lang="ES"
                style="
                  font-size: 10pt;
                  font-family: 'Montserrat';
                  color: #8ab0a1;
                  text-transform: uppercase;
                "
                >DERECHOS DE PROPIEDAD INTELECTUAL E INDUSTRIAL.</span
              ></b
            ><span
              lang="ES"
              style="
                font-size: 10pt;
                font-family: 'Montserrat';
                color: #8ab0a1;
              "
            >
            </span
            ><span
              lang="ES"
              style="
                font-size: 10pt;
                font-family: 'Montserrat';
                color: #8ab0a1;
              "
              >Todos los derechos de propiedad intelectual e industrial y
              comercial sobre la LAKY son titularidad de READY2PARK S.L., tal y
              consta en los registros correspondientes,&nbsp;Siendo de su
              propiedad el ejercicio exclusivo de los derechos de explotación de
              los mismos en cualquier forma y, en especial,&nbsp;los derechos de
              reproducción, distribución, comunicación pública y
              transformación</span
            >
          </p>

          <p
            class="MsoNormal"
            style="
              margin-top: 0in;
              margin-right: 14.2pt;
              margin-bottom: 0in;
              margin-left: 14.2pt;
              margin-bottom: 0.0001pt;
              text-align: justify;
              line-height: 12pt;
              background: transparent;
              vertical-align: baseline;
            "
          >
            <span
              lang="ES"
              style="
                font-size: 10pt;
                font-family: 'Montserrat';
                color: #8ab0a1;
              "
              >El USUARIO No podrá realizar reproducción, modificación,
              distribución, comercialización, desensamblado,&nbsp;utilización de
              técnicas de ingeniería inversa o de cualquier otro medio para
              obtener el código fuente, transformación o&nbsp;publicación de
              cualquier resultado de pruebas de referencias no autorizadas de
              cualquiera de los elementos y utilidades&nbsp;integradas dentro
              del desarrollo sin autorización expresa, constituyendo una
              infracción de los derechos de propiedad intelectual de READY2PARK
              S.L., S.L.,&nbsp; obligándose, en consecuencia, a no realizar
              ninguna de las acciones mencionadas.</span
            >
          </p>

          <p
            class="MsoNormal"
            style="
              margin-top: 0in;
              margin-right: 14.2pt;
              margin-bottom: 0in;
              margin-left: 14.2pt;
              margin-bottom: 0.0001pt;
              text-align: justify;
              line-height: 12pt;
              background: transparent;
            "
          ><br>
            <b
              ><span
                lang="ES"
                style="
                  font-size: 10pt;
                  font-family: 'Montserrat';
                  color: #8ab0a1;
                  text-transform: uppercase;
                "
                >11. POLITICA DE PRIVACIDAD.
              </span></b
            ><span
              lang="ES"
              style="
                font-size: 10pt;
                font-family: 'Montserrat';
                color: #8ab0a1;
              "
              >Sólo podrán registrarse y hacer uso del Servicio personas mayores
              de 18 años. En el momento del registro habrán de aportar todos los
              datos personales que, de manera obligatoria, se le soliciten
              (podrán solicitarse otros datos a aportar de manera voluntaria).
              El USUARIO responderá en todo momento de la veracidad y
              actualización de los datos, personales o de cualquier tipo,
              propios y ajenos que aporte para el registro en el Servicio o con
              motivo del uso de este. La mera sospecha sobre la falta de
              veracidad o actualización de tales datos habilitará a READY2PARK
              S.L. para bloquear al USUARIO y, si así lo decidiera bajo su
              propio criterio, resolver la relación entre las Partes, sin
              derecho a compensación o indemnización alguna a favor del USUARIO
              / PROPIETARIO. </span
            ><span
              lang="ES"
              style="
                font-size: 10pt;
                font-family: 'Montserrat';
                color: #8ab0a1;
              "
              >De conformidad con lo dispuesto en las normativas vigentes en
              protección de datos personales, el Reglamento (UE)&nbsp;2016/679
              de 27 de abril de 2016 (GDPR), se informa que los datos de
              carácter personal proporcionados mediante la&nbsp;aceptación de
              estos Términos y Condiciones, formarán parte de un fichero
              responsabilidad de READY2PARK S.L. y que estos serán tratados con
              la finalidad siguiente:</span
            >
          </p>

          <p
            class="MsoNormal"
            style="
              margin-top: 0in;
              margin-right: 14.2pt;
              margin-bottom: 0in;
              margin-left: 14.2pt;
              margin-bottom: 0.0001pt;
              text-align: justify;
              line-height: 12pt;
              background: transparent;
            "
          >
            <span
              lang="ES"
              style="
                font-size: 10pt;
                font-family: 'Montserrat';
                color: #8ab0a1;
              "
              >1. OBJETO de este documento y serán&nbsp;conservados mientras
              dure la relación contractual objeto del uso de la APLICACIÓN, con
              el único objetivo de facilitar la&nbsp;introducción de mejoras en
              futuras versiones de la APLICACIÓN, también podrá realizarse el
              tratamiento de la información&nbsp;de las instalaciones, accesos
              de USUARIO, datos demográficos, pantallas e interacción del
              USUARIO y bloqueos y&nbsp;excepciones. Asimismo, se informa que
              podrá retirar el consentimiento en cualquier momento y ejercer los
              derechos de&nbsp;acceso, rectificación, supresión, portabilidad,
              limitación y oposición dirigiéndose a </span
            ><span
              lang="ES"
              style="
                font-size: 10pt;
                font-family: 'Montserrat';
                color: #8ab0a1;
              "
              >LAKY, Ronda de Segovia 12, 10C,</span
            ><span
              lang="ES"
              style="
                font-size: 10pt;
                font-family: 'Montserrat';
                color: #8ab0a1;
              "
              >. Email: policy@laky.es.  READY2PARK S.L. se reserva la facultad
              de efectuar, en cualquier momento y sin necesidad de previo
              aviso,&nbsp;modificaciones y actualizaciones en la APLICACIÓN.
            </span>
          </p>

          <p
            class="MsoNormal"
            style="
              margin-top: 0in;
              margin-right: 14.2pt;
              margin-bottom: 0in;
              margin-left: 14.2pt;
              margin-bottom: 0.0001pt;
              text-align: justify;
              line-height: 12pt;
              background: transparent;
            "
          >
            <span
              lang="ES"
              style="
                font-size: 10pt;
                font-family: 'Montserrat';
                color: #8ab0a1;
              "
              >Asimismo, también se reserva el derecho a modificar los
              presentes&nbsp;Términos y Condiciones con el objetivo de
              adaptarlos a las posibles novedades legislativas y cambios en la
              propia&nbsp;APLICACIÓN, así como a las que se puedan derivar de
              los códigos tipos existentes en la materia o por
              motivos&nbsp;estratégicos o corporativos.</span
            >
          </p>

          <p
            class="MsoNormal"
            style="
              margin-top: 0in;
              margin-right: 14.2pt;
              margin-bottom: 0in;
              margin-left: 14.2pt;
              margin-bottom: 0.0001pt;
              text-align: justify;
              line-height: 12pt;
              background: transparent;
            "
          ><br>
            <b
              ><span
                lang="ES"
                style="
                  font-size: 10pt;
                  font-family: 'Montserrat';
                  color: #8ab0a1;
                  text-transform: uppercase;
                "
                >12. EXCLUSIÓN DE RESPONSABILIDAD.
              </span></b
            ><span
              lang="ES"
              style="
                font-size: 10pt;
                font-family: 'Montserrat';
                color: #8ab0a1;
              "
              >READY2PARK, S.L. se reserva el derecho de editar, actualizar,
              modificar, suspender, eliminar o finalizar los&nbsp;servicios
              ofrecidos por la Aplicación, incluyendo todo o parte de su
              contenido, sin necesidad de previo aviso, así como
              de&nbsp;modificar la forma o tipo de acceso a esta. Las posibles
              causas de modificación pueden tener lugar, por motivos tales, como
              su adaptación a las posibles novedades&nbsp;legislativas y cambios
              en la propia Aplicación, así como a las que se puedan derivar de
              los códigos tipos existentes en la&nbsp;materia o por motivos
              estratégicos o corporativos. READY2PARK, S.L. no será responsable
              del uso de la APLICACIÓN por un menor de edad, siendo la
              descarga&nbsp;y uso de la APLICACIÓN de la exclusiva
              responsabilidad del USUARIO La APLICACIÓN se presta sin ninguna
              clase de garantía. READY2PARK, S.L. no se hace&nbsp;responsable de
              la calidad final de la APLICACIÓN ni de que ésta sirva y cumpla
              con todos los objetivos de la misma. No&nbsp;obstante, lo
              anterior, READY2PARK, S.L. se compromete en la medida de sus
              posibilidades a contribuir a&nbsp;mejorar la calidad de la
              APLICACIÓN, pero no puede garantizar la precisión ni la actualidad
              del contenido de la misma. La responsabilidad de uso de la
              APLICACIÓN corresponde sólo al USUARIO Salvo lo establecido en
              estos Términos y&nbsp;Condiciones, READY2PARK S.L. no es
              responsable de ninguna pérdida o daño que se produzca en
              relación&nbsp;con la descarga o el uso de la APLICACIÓN, tales
              como los producidos como consecuencia de fallos, averías o
              bloqueos&nbsp;en el funcionamiento de la APLICACIÓN (por ejemplo,
              y sin carácter limitativo: error en las líneas de
              comunicaciones,&nbsp;defectos en el hardware o software de la
              APLICACIÓN o fallos en la red de Internet). Igualmente, READY2PARK
              S.L. tampoco será responsable de los daños producidos como
              consecuencia de un uso indebido o inadecuado de&nbsp;la APLICACIÓN
              por parte de los USUARIOs</span
            >
          </p>

          <p
            class="MsoNormal"
            style="
              margin-top: 0in;
              margin-right: 14.2pt;
              margin-bottom: 0in;
              margin-left: 14.2pt;
              margin-bottom: 0.0001pt;
              text-align: justify;
              line-height: 12pt;
              background: transparent;
              vertical-align: baseline;
            "
          ><br>
            <b
              ><span
                lang="ES"
                style="
                  font-size: 10pt;
                  font-family: 'Montserrat';
                  color: #8ab0a1;
                "
                >13.</span
              ></b
            ><span
              lang="ES"
              style="
                font-size: 10pt;
                font-family: 'Montserrat';
                color: #8ab0a1;
              "
            >
              <b
                ><span style="text-transform: uppercase"
                  >INCIDENCIAS Y SERVICIO DE ATENCIÓN AL CLIENTE.</span
                ></b
              ></span
            ><span
              lang="ES"
              style="
                font-size: 10pt;
                font-family: 'Montserrat';
                color: #8ab0a1;
              "
            >
              El titular de la aplicación tiene a disposición del USUARIO  un
              Centro de Atención al Cliente, que presta el servicio de
              asistencia técnica telefónica y/o a través de medios electrónicos.
              La dirección a la que podrá dirigirse el CLIENTE para la
              prestación de estos servicios, según la línea de
              producto/servicio, es info@laky.es.</span
            >
          </p>

          <p
            class="MsoNormal"
            style="
              margin-top: 0in;
              margin-right: 14.2pt;
              margin-bottom: 0in;
              margin-left: 14.2pt;
              margin-bottom: 0.0001pt;
              text-align: justify;
              line-height: 12pt;
              background: transparent;
              vertical-align: baseline;
            "
          ><br>
            <b
              ><span
                lang="ES"
                style="
                  font-size: 10pt;
                  font-family: 'Montserrat';
                  color: #8ab0a1;
                  text-transform: uppercase;
                "
                >14. LEGISLACIÓN Y FUERO.
              </span></b
            ><span
              lang="ES"
              style="
                font-size: 10pt;
                font-family: 'Montserrat';
                color: #8ab0a1;
              "
              >El USUARIO / PROPIETARIO acepta que la legislación aplicable y
              los Juzgados y Tribunales competentes para conocer de las
              divergencias&nbsp;derivadas de la interpretación o aplicación de
              este clausulado son los españoles, y se somete, con renuncia
              expresa a&nbsp;cualquier otro fuero, a los juzgados y tribunales
              de Madrid.</span
            >
          </p>

          <p
            class="MsoNormal"
            style="
              margin-top: 0in;
              margin-right: 14.2pt;
              margin-bottom: 0in;
              margin-left: 14.2pt;
              margin-bottom: 0.0001pt;
              text-align: justify;
              line-height: 12pt;
            "
          >
            <span
              lang="ES"
              style="font-size: 10pt; font-family: 'Calibri Light', sans-serif"
              >&nbsp;</span
            >
          </p>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- END SECTION PRIVACY -->
