 <!-- START HEADER -->
<header class="header_wrap fixed-top">
  <div class="container-fluid">
    <nav class="navbar navbar-expand-lg">
      <button
        class="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="ion-android-menu"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav m-auto">
          <li>
            <a class="nav-link page-scroll nav_item" href="#start">LAKY</a>
          </li>
          <li>
            <a class="nav-link page-scroll nav_item" href="#home">LAKY HOME</a>
          </li>
          <li>
            <a class="nav-link page-scroll nav_item" href="#business"
              >LAKY BUSINESS</a
            >
          </li>
          <li>
            <a class="nav-link page-scroll nav_item" href="#prices"
              >SUSCRIPCIONES</a
            >
          </li>
          <li>
            <a class="nav-link page-scroll nav_item" href="#news">NEWS</a>
          </li>
          <li>
            <a class="nav-link page-scroll nav_item" href="#team">EQUIPO</a>
          </li>
          <li>
            <a class="nav-link page-scroll nav_item" href="#contact"
              >CONTACTO</a
            >
          </li>
          <li>
            <a class="nav-link page-scroll nav_item download_nav" href="#start">
              <span class="btn-menu-download">DESCARGA LA APP</span>
            </a>
          </li>
        </ul>
      </div>
    </nav>
  </div>
</header>
<!-- END HEADER -->

<router-outlet></router-outlet>
