<!-- START SECTION PRIVACY -->
<section id="start" class="section_banner section_laky_start_1">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-10 res_md_mt_50 res_sm_mt_20 pt-lg-30">
        <p style="text-align: center">
          <b
            ><u><span lang="ES" class="p1 ft3">POLITICA DE COOKIES</span></u></b
          >
        </p>
        <div id="page_1">
          <p class="p4 ft1">¿Qué son las cookies?</p>
          <p class="p5 ft3">
            Las cookies son archivos que se pueden descargar en su equipo a
            través de las páginas web/app. Son herramientas que tienen un papel
            esencial para la prestación de numerosos servicios de la sociedad de
            la información. Entre otros, permiten a una página web almacenar y
            recuperar información sobre los hábitos de navegación de un usuario
            o de su equipo y, dependiendo de la información obtenida, se pueden
            utilizar para reconocer al usuario y mejorar el servicio ofrecido.
          </p>
          <p class="p4 ft1">Tipos de cookies</p>
          <p class="p4 ft3">
            Según quien sea la entidad que gestione el dominio desde donde se
            envían las cookies y trate los datos que se obtengan se pueden
            distinguir dos tipos:
          </p>
          <p class="p5 ft3">
            <span class="ft4">•</span
            ><span class="ft5"
              >Cookies propias: aquéllas que se envían al equipo terminal del
              usuario desde un equipo o dominio gestionado por el propio editor
              y desde el que se presta el servicio solicitado por el
              usuario.</span
            >
          </p>
          <p class="p5 ft3">
            <span class="ft4">•</span
            ><span class="ft5"
              >Cookies de terceros: aquéllas que se envían al equipo terminal
              del usuario desde un equipo o dominio que no es gestionado por el
              editor, sino por otra entidad que trata los datos obtenidos través
              de las cookies.</span
            >
          </p>
          <p class="p5 ft3">
            En el caso de que las cookies sean instaladas desde un equipo o
            dominio gestionado por el propio editor pero la información que se
            recoja mediante éstas sea gestionada por un tercero, no pueden ser
            consideradas como cookies propias.
          </p>
          <p class="p5 ft3">
            Existe también una segunda clasificación según el plazo de tiempo
            que permanecen almacenadas en el navegador del cliente, pudiendo
            tratarse de:
          </p>
          <p class="p9 ft3">
            <span class="ft4">•</span
            ><span class="ft5"
              >Cookies de sesión: diseñadas para recabar y almacenar datos
              mientras el usuario accede a una página web. Se suelen emplear
              para almacenar información que solo interesa conservar para la
              prestación del servicio solicitado por el usuario en una sola
              ocasión (p.e. una lista de productos adquiridos).</span
            >
          </p>
          <p class="p9 ft3">
            <span class="ft4">•</span
            ><span class="ft5"
              >Cookies persistentes: los datos siguen almacenados en el terminal
              y pueden ser accedidos y tratados durante un periodo definido por
              el responsable de la cookie, y que puede ir de unos minutos a
              varios años.</span
            >
          </p>
          <p class="p4 ft1">
            Por último, existe otra clasificación con cinco tipos de cookies
            según la finalidad para la que se traten los datos obtenidos:
          </p>
        </div>
        <div id="page_2">
          <div id="id2_1">
            <p class="p9 ft3">
              <span class="ft4">•</span
              ><span class="ft7"
                >Cookies técnicas: aquellas que permiten al usuario la
                navegación a través de una página web, plataforma o aplicación y
                la utilización de las diferentes opciones o servicios que en
                ella existan como, por</span
              >
            </p>
            <p class="p14 ft3">
              ejemplo, controlar el tráfico y la comunicación de datos,
              identificar la sesión, acceder a partes de acceso restringido,
              recordar los elementos que integran un pedido, realizar el proceso
              de compra de un pedido, realizar la solicitud de inscripción o
              participación en un evento, utilizar elementos de seguridad
              durante la navegación, almacenar contenidos para la difusión de
              vídeos o sonido o compartir contenidos a través de redes sociales.
            </p>
            <p class="p14 ft3">
              <span class="ft4">•</span
              ><span class="ft5"
                >Cookies de personalización: permiten al usuario acceder al
                servicio con algunas características de carácter general
                predefinidas en función de una serie de criterios en el terminal
                del usuario como por ejemplo serian el idioma, el tipo de
                navegador a través del cual accede al servicio, la configuración
                regional desde donde accede al servicio, etc.</span
              >
            </p>
            <p class="p14 ft3">
              <span class="ft4">•</span
              ><span class="ft5"
                >Cookies de análisis: permiten al responsable de las mismas, el
                seguimiento y análisis del comportamiento de los usuarios de los
                sitios web a los que están vinculadas. La información recogida
                mediante este tipo de cookies se utiliza en la medición de la
                actividad de los sitios web, aplicación o plataforma y para la
                elaboración de perfiles de navegación de los usuarios de dichos
                sitios, aplicaciones y plataformas, con el fin de introducir
                mejoras en función del análisis de los datos de uso que hacen
                los usuarios del servicio.</span
              >
            </p>
            <p class="p17 ft4">
              <span class="ft4">•</span
              ><span class="ft9"
                >Cookies publicitarias: permiten la gestión, de la forma más
                eficaz posible, de los espacios publicitarios.</span
              >
            </p>
            <p class="p18 ft3">
              <span class="ft4">•</span
              ><span class="ft5"
                >Cookies de publicidad comportamental: almacenan información del
                comportamiento de los usuarios obtenida a través de la
                observación continuada de sus hábitos de navegación, lo que
                permite desarrollar un perfil específico para mostrar publicidad
                en función del mismo.</span
              >
            </p>
            <p class="p19 ft3">
              <span class="ft4">•</span
              ><span class="ft5"
                >Cookies de redes sociales externas: se utilizan para que los
                visitantes puedan interactuar con el contenido de diferentes
                plataformas sociales (facebook, youtube, twitter, linkedIn,
                etc..) y que se generen únicamente para los usuarios de dichas
                redes sociales. Las condiciones de utilización de estas cookies
                y la información recopilada se regula por la política de
                privacidad de la plataforma social correspondiente.</span
              >
            </p>
            <p class="p20 ft1">Desactivación y eliminación de cookies</p>
            <p class="p19 ft3">
              Tienes la opción de permitir, bloquear o eliminar las cookies
              instaladas en tu equipo mediante la configuración de las opciones
              del navegador instalado en su equipo. Al desactivar cookies,
              algunos de los servicios disponibles podrían dejar de estar
              operativos. La forma de deshabilitar las cookies es diferente para
              cada navegador, pero normalmente puede hacerse desde el menú
              Herramientas u Opciones. También puede consultarse el menú de
              Ayuda del navegador dónde puedes encontrar instrucciones. El
              usuario podrá en cualquier momento elegir qué cookies quiere que
              funcionen en este sitio web.
            </p>
            <p class="p19 ft3">
              Puede usted permitir, bloquear o eliminar las cookies instaladas
              en su equipo mediante la configuración de las opciones del
              navegador instalado en su ordenador:
            </p>
          </div>
        </div>
        <div id="page_3">
          <div id="id3_1">
            <p class="p24 ft3">
              <span class="ft4">•</span
              ><span class="ft10"
                >Microsoft Internet Explorer o Microsoft Edge: </span
              >http://windows.microsoft.com/es-es/windows-vista/Block-or-allow-cookies
            </p>
            <p class="p25 ft4">
              <span class="ft4">•</span
              ><span class="ft9">Mozilla Firefox: </span
              >http://support.mozilla.org/es/kb/impedir-que-los-sitios-web-guarden-sus-preferencia
            </p>
            <p class="p25 ft4">
              <span class="ft4">•</span
              ><span class="ft9"
                >Chrome:
                https://support.google.com/accounts/answer/61416?hl=es</span
              >
            </p>
            <p class="p26 ft4">
              <span class="ft4">•</span
              ><span class="ft9">Safari: </span
              >http://safari.helpmax.net/es/privacidad-y-seguridad/como-gestionar-las-cookies/
            </p>
            <p class="p26 ft4">
              <span class="ft4">•</span
              ><span class="ft9">Opera: </span
              >http://help.opera.com/Linux/10.60/es-ES/cookies.html
            </p>
            <p class="p19 ft3">
              Además, también puede gestionar el almacén de cookies en su
              navegador a través de herramientas como las siguientes
            </p>
            <p class="p26 ft4">
              <span class="ft4">•</span
              ><span class="ft9">Ghostery: www.ghostery.com/</span>
            </p>
            <p class="p26 ft4">
              <span class="ft4">•</span
              ><span class="ft9"
                >Your online choices: www.youronlinechoices.com/es/</span
              >
            </p>
            <p class="p4 ft1">Cookies utilizadas en Ready2park</p>
            <p class="p19 ft3">
              A continuación, se identifican las cookies que están siendo
              utilizadas en este portal, así como su tipología.
            </p>
            <p class="p19 ft1">Aceptación de la Política de cookies</p>
            <p class="p19 ft3">
              READY2PARK asume que usted acepta el uso de cookies. No obstante,
              muestra información sobre su Política de cookies en la parte
              inferior o superior de cualquier página del portal con cada inicio
              de sesión con el objeto de que usted sea consciente.
            </p>
            <p class="p19 ft3">
              Ante esta información es posible llevar a cabo las siguientes
              acciones:
            </p>
            <p class="p19 ft3">
              <span class="ft4">•</span
              ><span class="ft5"
                >Aceptar cookies. No se volverá a visualizar este aviso al
                acceder a cualquier página del portal durante la presente
                sesión.</span
              >
            </p>
            <p class="p19 ft3">
              <span class="ft4">•</span
              ><span class="ft9"
                >Cerrar. Se oculta el aviso en la presente página.</span
              >
            </p>
            <p class="p19 ft3">
              <span class="ft4">•</span
              ><span class="ft5"
                >Modificar su configuración. Podrá obtener más información sobre
                qué son las cookies, conocer la Política de cookies ready2park y
                modificar la configuración de su navegador.</span
              >
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- END SECTION PRIVACY -->
