import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import {
  NgcCookieConsentConfig,
  NgcCookieConsentModule,
} from 'ngx-cookieconsent';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeModule } from './components/home/home.module';

const cookieConfig: NgcCookieConsentConfig = {
  cookie: {
    domain: 'laky.es',
  },
  position: 'bottom',
  theme: 'classic',
  palette: {
    popup: {
      background: '#000000',
      text: '#8ab0a1',
      link: '#ffffff',
    },
    button: {
      background: '#8ab0a1',
      text: '#000000',
      border: 'transparent',
    },
  },
  type: 'opt-out',
  content: {
    message:
      'El sitio web READY2PARK.APP utiliza cookies propias y de terceros para recopilar información que ayuda a optimizar su visita a sus páginas web. \nNo se utilizarán las cookies para recoger información de carácter personal. Usted puede permitir su uso o rechazarlo, también puede cambiar su configuración siempre que lo desee.\nEncontrará más información en nuestra Política de Cookies.',
    deny: 'Modificar mi configuración',
    link: 'Leer más.',
    href: '/cookies',
    target: '_blank',
    allow: 'Permitir Cookies',
  },
};

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgcCookieConsentModule.forRoot(cookieConfig),
    HomeModule,
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
