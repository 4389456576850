<!-- START SECTION BANNER -->
<section id="start" class="section_banner section_laky_start_1">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-12 res_md_mt_50 res_sm_mt_20 pt-lg-30">
        <div class="divider small_divider d-lg-block"></div>

        <div class="banner_text_s2 text_md_center text-center">
          <img
            src="assets/images/laky_animated.svg"
            class="logo-size"
            alt="laky_logo"
          />

          <h1 class="ptb-10">
            <strong
              class="logo-text animation"
              data-animation="fadeInUp"
              data-animation-delay="2.0s"
              >​L</strong
            >
            <strong
              class="logo-text animation"
              data-animation="fadeInUp"
              data-animation-delay="2.1s"
              >​A</strong
            >
            <strong
              class="logo-text animation"
              data-animation="fadeInUp"
              data-animation-delay="2.2s"
              >K</strong
            >
            <strong
              class="logo-text animation"
              data-animation="fadeInUp"
              data-animation-delay="2.3s"
              >Y</strong
            >
            <strong
              class="logo-text animation"
              data-animation="fadeInUp"
              data-animation-delay="2.4s"
              >.
            </strong>
            <strong
              class="logo-text animation"
              data-animation="fadeInUp"
              data-animation-delay="2.5s"
              >T</strong
            >
            <strong
              class="logo-text animation"
              data-animation="fadeInUp"
              data-animation-delay="2.6s"
              >h</strong
            >
            <strong
              class="logo-text animation"
              data-animation="fadeInUp"
              data-animation-delay="2.7s"
              >e
            </strong>
            <strong
              class="logo-text animation"
              data-animation="fadeInUp"
              data-animation-delay="2.8s"
              >d</strong
            >
            <strong
              class="logo-text animation"
              data-animation="fadeInUp"
              data-animation-delay="2.9s"
              >i</strong
            >
            <strong
              class="logo-text animation"
              data-animation="fadeInUp"
              data-animation-delay="3s"
              >g</strong
            >
            <strong
              class="logo-text animation"
              data-animation="fadeInUp"
              data-animation-delay="3.1s"
              >i</strong
            >
            <strong
              class="logo-text animation"
              data-animation="fadeInUp"
              data-animation-delay="3.2s"
              >t</strong
            >
            <strong
              class="logo-text animation"
              data-animation="fadeInUp"
              data-animation-delay="3.3s"
              >a</strong
            >
            <strong
              class="logo-text animation"
              data-animation="fadeInUp"
              data-animation-delay="3.4s"
              >l
            </strong>
            <strong
              class="logo-text animation"
              data-animation="fadeInUp"
              data-animation-delay="3.5s"
              >K</strong
            >
            <strong
              class="logo-text animation"
              data-animation="fadeInUp"
              data-animation-delay="3.6s"
              >e</strong
            >
            <strong
              class="logo-text animation"
              data-animation="fadeInUp"
              data-animation-delay="3.7s"
              >y</strong
            >
            <strong
              class="logo-text animation"
              data-animation="fadeInUp"
              data-animation-delay="3.8s"
              >.</strong
            >
          </h1>

          <div
            class="btn_group animation"
            data-animation="fadeInUp"
            data-animation-delay="4.0s"
          >
            <a
              href="https://play.google.com/store/apps/details?id=com.ready2park.laky"
              target="_blank"
            >
              <img
                src="assets/images/google-play.png"
                class="stores-btn"
                alt="google_play"
              />
            </a>
            <a
              href="https://apps.apple.com/us/app/laky/id1512449912"
              target="_blank"
            >
              <img
                src="assets/images/app-store.png"
                class="stores-btn"
                alt="app_store"
              />
            </a>
          </div>

          <div class="row justify-content-center ptb-20">
            <div class="col-lg-2">
              <div
                class="btn_group animation flex"
                data-animation="fadeInUp"
                data-animation-delay="5.0s"
              >
                <a href="https://www.instagram.com/lakyaccess/" target="_blank">
                  <img
                    src="assets/images/instagram.svg"
                    class="social-btn"
                    alt="instagram"
                  />
                </a>
                <a href="https://www.facebook.com/lakyaccess" target="_blank">
                  <img
                    src="assets/images/facebook.svg"
                    class="social-btn"
                    alt="facebook"
                  />
                </a>
                <a
                  href="https://www.youtube.com/channel/UCJMmc4JPqA3gL6-aMn2wrJw"
                  target="_blank"
                >
                  <img
                    src="assets/images/youtube.png"
                    class="social-btn"
                    alt="youtube"
                  />
                </a>
                <a
                  href="https://www.linkedin.com/company/laky-the-digital-key/"
                  target="_blank"
                >
                  <img
                    src="assets/images/linkedin.svg"
                    class="social-btn"
                    alt="linkedin"
                  />
                </a>
              </div>
            </div>
          </div>

          <a
            href="#about"
            class="animation page-scroll"
            data-animation="fadeInUp"
            data-animation-delay="6.0s"
          >
            <img src="assets/images/flecha.png" class="arrow" alt="flecha" />
          </a>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- END SECTION BANNER -->

<!-- START SECTION ABOUT -->
<section id="about" class="section_laky_start_2">
  <div class="container">
    <div class="res_md_mt_50 res_sm_mt_20 pt-lg-30">
      <div class="banner_text_s2 text_md_center text-center">
        <h1 class="ptb-20">
          <strong
            class="logo-text animation txt-anim-1"
            data-animation="fadeInUp"
            data-animation-delay="2.0s"
            >​E</strong
          >
          <strong
            class="logo-text animation txt-anim-2"
            data-animation="fadeInUp"
            data-animation-delay="2.1s"
            >​a</strong
          >
          <strong
            class="logo-text animation txt-anim-3"
            data-animation="fadeInUp"
            data-animation-delay="2.2s"
            >s</strong
          >
          <strong
            class="logo-text animation txt-anim-4"
            data-animation="fadeInUp"
            data-animation-delay="2.3s"
            >y</strong
          >
          <strong
            class="logo-text animation txt-anim-5"
            data-animation="fadeInUp"
            data-animation-delay="2.4s"
            >.
          </strong>
          <strong
            class="logo-text animation txt-anim-6"
            data-animation="fadeInUp"
            data-animation-delay="2.5s"
            >B</strong
          >
          <strong
            class="logo-text animation txt-anim-7"
            data-animation="fadeInUp"
            data-animation-delay="2.6s"
            >e
          </strong>
          <strong
            class="logo-text animation txt-anim-8"
            data-animation="fadeInUp"
            data-animation-delay="2.7s"
            >L</strong
          >
          <strong
            class="logo-text animation txt-anim-9"
            data-animation="fadeInUp"
            data-animation-delay="2.8s"
            >A</strong
          >
          <strong
            class="logo-text animation txt-anim-10"
            data-animation="fadeInUp"
            data-animation-delay="2.9s"
            >K</strong
          >
          <strong
            class="logo-text animation txt-anim-11"
            data-animation="fadeInUp"
            data-animation-delay="3s"
            >Y</strong
          >
        </h1>

        <div class="row justify-content-center">
          <div
            class="home-desc col-xl-8 col-md-12 col-xs-12 animation"
            data-animation="fadeInUp"
            data-animation-delay="1s"
          >
            LAKY es la aplicación que te permite gestionar cualquier tipo de
            acceso desde tu móvil. Con tan solo un click abre la puerta de tu
            garaje, el portal de tu comunidad o el acceso a tu oficina.
          </div>
          <div class="divider small_divider d-lg-block"></div>
          <div
            class="home-desc col-xl-8 col-md-12 col-xs-12 animation"
            data-animation="fadeInUp"
            data-animation-delay="2s"
          >
            Además puedes compartir de forma segura tus accesos con otros
            usuarios cediéndolos de manera temporal o permanente.
          </div>
          <div class="divider small_divider d-lg-block"></div>
          <div
            class="home-desc col-xl-8 col-md-12 col-xs-12 animation"
            data-animation="fadeInUp"
            data-animation-delay="3s"
          >
            Si eres particular y deseas instalar LAKY en tu comunidad para
            acceder de forma cómoda y segura a tu finca o garaje desde tu móvil
            te ofrecemos LAKY Home.
          </div>
          <div class="divider small_divider d-lg-block"></div>
          <div
            class="home-desc col-xl-8 col-md-12 col-xs-12 animation"
            data-animation="fadeInUp"
            data-animation-delay="4s"
          >
            Si eres empresa y quieres hacer una gestión segura, remota e incluso
            personalizada de tus accesos y los de tus empleados, elige LAKY
            Business.
          </div>
        </div>

        <div class="divider small_divider d-lg-block"></div>

        <div class="btn_group">
          <a class="video-btn" data-toggle="modal" data-target="#myModal">
            <img src="assets/images/play.png" class="btn-video" alt="play" />
          </a>
        </div>

        <a class="page-scroll" href="#home">
          <img src="assets/images/flecha.png" class="arrow" alt="flecha" />
        </a>
      </div>
    </div>
  </div>
</section>
<!-- END SECTION ABOUT -->

<!-- START SECTION HOME -->
<section id="home" class="section_laky_home">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-12 res_md_mt_50 res_sm_mt_20 pt-lg-30">
        <h1 class="text-center">
          <strong class="logo-header">LAKY HOME</strong><br />
          <span class="logo-header-text-sub">
            La llave digital para tu comunidad
          </span>
        </h1>

        <div class="divider small_divider d-sm-none d-md-block d-lg-none"></div>

        <div
          id="carouselHome"
          class="carousel slide carousel-fade"
          data-ride="carousel"
          data-interval="false"
        >
          <div class="carousel-inner">
            <div class="carousel-item active">
              <div class="row justify-content-end pd-t-15">
                <div class="col-lg-5 col-sm-12 align-center">
                  <div class="business-text">
                    <p class="business-title">PASO 1 - DESCARGA</p>
                    <p class="business-subtitle">
                      Descarga la APP en tu dispositivo
                    </p>
                    <p class="business-desc">
                      Descarga la Aplicación LAKY de forma gratuita.<br />Si
                      tienes un dispositivo Android ve a Google Play y si tienes
                      un dispositivo iPhone ve a tu App Store.
                    </p>
                  </div>
                </div>
                <div class="col-lg-6 col-sm-12 text-center">
                  <a
                    class="carousel-control-prev"
                    href="#carouselHome"
                    role="button"
                    data-slide="prev"
                  >
                    <span
                      class="carousel-control-prev-icon"
                      aria-hidden="true"
                    ></span>
                    <span class="sr-only">Previous</span>
                  </a>
                  <img
                    src="assets/images/iphone-b.png"
                    class="business-phone"
                    alt="iphone-b"
                  />
                  <a
                    class="carousel-control-next"
                    href="#carouselHome"
                    role="button"
                    data-slide="next"
                  >
                    <span
                      class="carousel-control-next-icon"
                      aria-hidden="true"
                    ></span>
                    <span class="sr-only">Next</span>
                  </a>
                </div>
              </div>
            </div>
            <div class="carousel-item">
              <div class="row justify-content-end pd-t-15">
                <div class="col-lg-5 col-sm-12 align-center">
                  <div class="business-text">
                    <p class="business-title">PASO 2 - REGÍSTRATE Y SOLICITA</p>
                    <p class="business-subtitle">
                      Gestiona digitalmente todos los accesos a tu comunidad
                      desde tu teléfono móvil
                    </p>
                    <p class="business-desc">
                      Regístrate en la app y solicita la instalación del
                      dispositivo de apertura LAKY en tu acceso desde la propia
                      app o poniéndote en contacto con nosotros. Olvídate de las
                      llaves y los duplicados de los mandos, ahorra tiempo y
                      dinero a la hora de entrar en tu finca o tu garaje.
                    </p>
                  </div>
                </div>
                <div class="col-lg-6 col-sm-12 text-center">
                  <a
                    class="carousel-control-prev"
                    href="#carouselHome"
                    role="button"
                    data-slide="prev"
                  >
                    <span
                      class="carousel-control-prev-icon"
                      aria-hidden="true"
                    ></span>
                    <span class="sr-only">Previous</span>
                  </a>
                  <img
                    src="assets/images/home-2.gif"
                    class="business-phone"
                    alt="iphone-2"
                  />
                  <a
                    class="carousel-control-next"
                    href="#carouselHome"
                    role="button"
                    data-slide="next"
                  >
                    <span
                      class="carousel-control-next-icon"
                      aria-hidden="true"
                    ></span>
                    <span class="sr-only">Next</span>
                  </a>
                </div>
              </div>
            </div>
            <div class="carousel-item">
              <div class="row justify-content-end pd-t-15">
                <div class="col-lg-5 col-sm-12 align-center">
                  <div class="business-text">
                    <p class="business-title">PASO 3 - CONFIGURA Y COMPARTE</p>
                    <p class="business-subtitle">
                      Crea tus accesos y suscríbete a la tarifa que más
                      se ajuste a tus necesidades
                    </p>
                    <p class="business-desc">
                      <span>&#10003; </span>Crea tu acceso y suscríbete a la
                      tarifa que desees, mensual o anual. <br />
                      <span>&#10003; </span>Abre cómodamente tu acceso desde tu
                      teléfono móvil y compártelo con tus familiares o amigos,
                      te ofrecemos hasta 5 llaves digitales.
                    </p>
                  </div>
                </div>
                <div class="col-lg-6 col-sm-12 text-center">
                  <a
                    class="carousel-control-prev"
                    href="#carouselHome"
                    role="button"
                    data-slide="prev"
                  >
                    <span
                      class="carousel-control-prev-icon"
                      aria-hidden="true"
                    ></span>
                    <span class="sr-only">Previous</span>
                  </a>
                  <img
                    src="assets/images/home-3.gif"
                    class="business-phone"
                    alt="iphone-3"
                  />
                  <a
                    class="carousel-control-next"
                    href="#carouselHome"
                    role="button"
                    data-slide="next"
                  >
                    <span
                      class="carousel-control-next-icon"
                      aria-hidden="true"
                    ></span>
                    <span class="sr-only">Next</span>
                  </a>
                </div>
              </div>
            </div>
            <div class="carousel-item">
              <div class="row justify-content-end pd-t-15">
                <div class="col-lg-5 col-sm-12 align-center">
                  <div class="business-text">
                    <p class="business-title">BE SAFE CON LAKY</p>
                    <p class="business-subtitle">
                      Lleva el control y registro de las aperturas a tus accesos
                    </p>
                    <p class="business-desc">
                      Tú decides quienes entran y cuándo a tu finca o garaje.
                      Controla desde el App como propietario del acceso el
                      registro online de los usuarios y aperturas a través de un
                      histórico.
                    </p>
                  </div>
                </div>
                <div class="col-lg-6 col-sm-12 text-center">
                  <a
                    class="carousel-control-prev"
                    href="#carouselHome"
                    role="button"
                    data-slide="prev"
                  >
                    <span
                      class="carousel-control-prev-icon"
                      aria-hidden="true"
                    ></span>
                    <span class="sr-only">Previous</span>
                  </a>
                  <img
                    src="assets/images/iphone-d.png"
                    class="business-phone"
                    alt="iphone-d"
                  />
                  <a
                    class="carousel-control-next"
                    href="#carouselHome"
                    role="button"
                    data-slide="next"
                  >
                    <span
                      class="carousel-control-next-icon"
                      aria-hidden="true"
                    ></span>
                    <span class="sr-only">Next</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <a class="page-scroll" href="#business">
        <img src="assets/images/flecha.png" class="arrow" alt="flecha" />
      </a>
    </div>
  </div>
</section>
<!-- END SECTION HOME -->

<!-- START SECTION BUSINESS -->
<section id="business" class="section_laky_bussiness">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-12 res_md_mt_50 res_sm_mt_20 pt-lg-30">
        <h1 class="text-center">
          <strong class="logo-header">LAKY BUSINESS</strong><br />
          <span class="logo-header-text-sub"
            >La gestión digital para los accesos de tu empresa</span
          >
        </h1>

        <div class="divider small_divider d-sm-none d-md-block d-lg-none"></div>

        <div id="carouselBusiness" class="carousel slide" data-interval="false">
          <div class="carousel-inner">
            <div class="carousel-item active">
              <div class="row justify-content-end pd-t-15">
                <div class="col-lg-5 col-sm-12 align-center">
                  <div class="business-text">
                    <p class="business-title">PASO 1 - SOLICITA</p>
                    <p class="business-subtitle">
                      Solicita la instalación de LAKY
                    </p>
                    <p class="business-desc">
                      Solicita la instalación como empresa del dispositivo de
                      apertura LAKY en los accesos peatonales o parking de tus
                      oficinas poniéndote en contacto con nosotros o desde la
                      propia aplicación que te puedes descargar de manera
                      gratuita.
                    </p>
                  </div>
                </div>
                <div class="col-lg-6 col-sm-12 text-center">
                  <a
                    class="carousel-control-prev"
                    href="#carouselBusiness"
                    role="button"
                    data-slide="prev"
                  >
                    <span
                      class="carousel-control-prev-icon"
                      aria-hidden="true"
                    ></span>
                    <span class="sr-only">Previous</span>
                  </a>
                  <img
                    src="assets/images/iphone-home.png"
                    class="business-phone"
                    alt="iphone-1"
                  />
                  <a
                    class="carousel-control-next"
                    href="#carouselBusiness"
                    role="button"
                    data-slide="next"
                  >
                    <span
                      class="carousel-control-next-icon"
                      aria-hidden="true"
                    ></span>
                    <span class="sr-only">Next</span>
                  </a>
                </div>
              </div>
            </div>
            <div class="carousel-item">
              <div class="row justify-content-end pd-t-15">
                <div class="col-lg-5 col-sm-12 align-center">
                  <div class="business-text">
                    <p class="business-title">PASO 2 - CONFIGURA Y COMPARTE</p>
                    <div class="business-desc">
                      <p class="business-subtitle">
                        Regístrate, crea tus accesos y gestiónalos de forma
                        segura
                      </p>
                      <div class="little-space">
                        Puedes contratar el Pack LAKY Business y disponer de
                        llaves digitales para compartir tus accesos con tus
                        empleados o colaboradores de tu empresa. Además puedes
                        cederlos de manera temporal o permanente, incluso por
                        franjas horarias.
                      </div>
                      <div>
                        Podrás también abrir tu acceso remotamente a través de
                        wifi y ceder este permiso a quién tu autorices así como
                        contar con histórico de aperturas como garantía de
                        seguridad.
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6 col-sm-12 text-center">
                  <a
                    class="carousel-control-prev"
                    href="#carouselBusiness"
                    role="button"
                    data-slide="prev"
                  >
                    <span
                      class="carousel-control-prev-icon"
                      aria-hidden="true"
                    ></span>
                    <span class="sr-only">Previous</span>
                  </a>
                  <img
                    src="assets/images/business-2.gif"
                    class="business-phone"
                    alt="iphone-2"
                  />
                  <a
                    class="carousel-control-next"
                    href="#carouselBusiness"
                    role="button"
                    data-slide="next"
                  >
                    <span
                      class="carousel-control-next-icon"
                      aria-hidden="true"
                    ></span>
                    <span class="sr-only">Next</span>
                  </a>
                </div>
              </div>
            </div>
            <div class="carousel-item">
              <div
                class="row justify-content-end pd-t-15"
                style="align-items: center"
              >
                <div class="col-lg-5 col-sm-12 align-center">
                  <div class="business-text">
                    <p class="business-title">PASO 3 - GESTIÓN A MEDIDA</p>
                    <p class="business-subtitle">
                      Solicita una gestión personalizada de los accesos de tu
                      empresa
                    </p>
                    <p class="business-desc">
                      Podrás solicitar una gestión personalizada adhoc a las
                      necesidades de tu empresa, un dashboard o plataforma web
                      que podemos desarrollar a medida para tu compañía.
                    </p>
                  </div>
                </div>
                <div class="col-lg-6 col-sm-12 text-center">
                  <a
                    class="carousel-control-prev"
                    href="#carouselBusiness"
                    role="button"
                    data-slide="prev"
                  >
                    <span
                      class="carousel-control-prev-icon"
                      aria-hidden="true"
                    ></span>
                    <span class="sr-only">Previous</span>
                  </a>
                  <img
                    src="assets/images/imac.png"
                    class="business-imac"
                    alt="imac"
                  />
                  <a
                    class="carousel-control-next"
                    href="#carouselBusiness"
                    role="button"
                    data-slide="next"
                  >
                    <span
                      class="carousel-control-next-icon"
                      aria-hidden="true"
                    ></span>
                    <span class="sr-only">Next</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <a class="page-scroll" href="#prices">
        <img src="assets/images/flecha.png" class="arrow" alt="flecha" />
      </a>
    </div>
  </div>
</section>
<!-- END SECTION BUSINESS -->

<!-- START SECTION PRICES -->
<section id="prices" class="section_laky_suscripciones">
  <div class="container">
    <div class="row justify-content-center">
      <h1 class="col-lg-12 text-center">
        <strong class="logo-header">NUESTRAS TARIFAS</strong><br />
        <span class="logo-header-text-sub"
          >Elige la tarifa que mejor se ajuste a ti</span
        >
      </h1>

      <div class="col-xl-3 col-lg-5 col-md-6 col-sm-9 pt-30">
        <div class="pricing_box res_sm_mt_20">
          <div class="pr_title green_light_bg">
            <h3><span class="price_period">29,90€/</span> Anual</h3>
            <div class="price_tage_2">
              <h3 class="pack-name">Laky Home</h3>
              <span class="font-13">
                Podrás disponer de hasta 5 llaves digitales para compartir tu
                acceso.
              </span>
            </div>
          </div>
          <div class="pr_content">
            <ul class="list_none pt-30 txt-white">
              <li class="font-15">
                <span>&#10003; </span>Instalación gratuita *
              </li>
              <li class="font-15">
                <span>&#10003; </span>Control y registro de accesos
              </li>
              <li class="font-15">
                <span>&#10003; </span>Sin coste adicional de mantenimiento
              </li>
              <li class="txt-explain">
                * Hasta un máximo de 2 dispositivos y comunidades de más de 20
                propietarios. Por encima de 2 dispositivos y viviendas
                unifamiliares o comunidades de menos de 20 propietarios a un
                coste de 150€/dispositivo. <br /><br />
                * Para puertas de vivienda solicitar presupuesto.
              </li>
            </ul>
          </div>
          <!-- <div class="pr_footer">
            <a href="#" class="btn btn-buy btn-radius">Buy Now</a>
          </div> -->
        </div>
      </div>

      <div class="offset-xl-2 col-xl-3 col-lg-5 col-md-6 col-sm-9 pt-30">
        <div class="pricing_box res_sm_mt_30">
          <div class="pr_title green_light_bg">
            <h3><span class="price_period">450€/</span> Anual</h3>
            <div class="price_tage_2">
              <h3 class="pack-name">Laky Business</h3>
              <span class="font-13">
                Podrás disponer de hasta 50 llaves digitales para compartir con
                tus empleados.
              </span>
            </div>
          </div>
          <div class="pr_content">
            <ul class="list_none pt-30 txt-white">
              <li class="font-15">
                <span>&#10003; </span>La instalación dependerá del número y
                tipología de accesos *
              </li>
              <li class="font-15">
                <span>&#10003; </span>Control y registro de accesos
              </li>
              <li class="font-15">
                <span>&#10003; </span>Podrás conectarte en remoto y ceder
                permisos
              </li>
              <li class="txt-explain">
                Podemos valorar adicionalmente el desarrollo de un Dashboard o
                plataforma web a medida.<br /><br />
                * Analizaremos los diferentes accesos cuya apertura desees
                gestionar de forma remota o digital.
              </li>
            </ul>
          </div>
          <!-- <div class="pr_footer">
            <a href="#" class="btn btn-buy btn-radius">Buy Now</a>
          </div> -->
        </div>
      </div>
    </div>
    <div class="text_md_center text-center">
      <a class="page-scroll" href="#team">
        <img src="assets/images/flecha.png" class="arrow" alt="flecha" />
      </a>
    </div>
  </div>
</section>
<!-- END SECTION PRICES -->

<!-- START SECTION NEWS -->
<section id="news" class="section_laky_news">
  <div class="container">
    <div class="row justify-content-center">
      <h1 class="col-lg-12 text-center">
        <strong class="logo-header">NEWS</strong><br />
        <span class="logo-header-text-sub">Laky en los medios</span>
      </h1>
    </div>
  </div>

  <div class="container-fluid">
    <div class="row justify-content-center">
      <div class="col-lg-12">
        <div id="carouselNews" class="carousel slide" data-interval="false">
          <div class="carousel-inner">
            <div class="carousel-item active">
              <a
                class="carousel-control-prev carousel-news-control-prev"
                href="#carouselNews"
                role="button"
                data-slide="prev"
              >
                <span
                  class="carousel-control-prev-icon"
                  aria-hidden="true"
                ></span>
                <span class="sr-only">Previous</span>
              </a>
              <div class="row justify-center lg_pt_50 white">
                <div class="col-lg-9 col-md-8 col-sm-8">
                  <div class="row justify-center">
                    <div
                      class="
                        col-lg-4 col-sm-6 col-xs-12
                        lg_pb_20
                        logo-news-container
                      "
                    >
                      <div class="row justify-content-center">
                        <a
                          href="https://www.cope.es/emisoras/comunidad-valenciana/castellon-provincia/castellon/noticias/nayar-systems-laky-unen-fuerzas-como-partners-tecnologicos-20210518_1295269"
                          target="_blank"
                        >
                          <img
                            src="assets/images/press/Logos-12.png"
                            class="logo-news"
                          />
                        </a>
                      </div>

                      <div class="row justify-content-center months">
                        mayo 2021
                      </div>
                    </div>

                    <div
                      class="
                        col-lg-4 col-sm-6 col-xs-12
                        lg_pb_20
                        logo-news-container
                      "
                    >
                      <div class="row justify-content-center">
                        <a
                          href="https://valenciaplaza.com/Nayar-Systems-startup-Laky-unen-fuerzas-impulsar-digitalizacion"
                          target="_blank"
                        >
                          <img
                            src="assets/images/press/Logos-04.png"
                            class="logo-news"
                          />
                        </a>
                      </div>

                      <div class="row justify-content-center months">
                        mayo 2021
                      </div>
                    </div>

                    <div
                      class="
                        col-lg-4 col-sm-6 col-xs-12
                        lg_pb_20
                        logo-news-container
                      "
                    >
                      <div class="row justify-content-center">
                        <a
                          href="https://intelectium.com/12-startups-del-sector-proptech/"
                          target="_blank"
                        >
                          <img
                            src="assets/images/press/Logos-13.png"
                            class="logo-news"
                          />
                        </a>
                      </div>

                      <div class="row justify-content-center months">
                        mayo 2021
                      </div>
                    </div>

                    <div
                      class="
                        col-lg-4 col-sm-6 col-xs-12
                        lg_pb_20
                        logo-news-container
                      "
                    >
                      <div class="row justify-content-center">
                        <a
                          href="https://www.levante-emv.com/el-mercantil-valenciano/2021/05/09/principio-llaves-51490685.html"
                          target="_blank"
                        >
                          <img
                            src="assets/images/press/Logos-10.png"
                            class="logo-news"
                          />
                        </a>
                      </div>

                      <div class="row justify-content-center months">
                        mayo 2021
                      </div>
                    </div>
                    <div
                      class="
                        col-lg-4 col-sm-6 col-xs-12
                        lg_pb_20
                        logo-news-container
                      "
                    >
                      <div class="row justify-content-center">
                        <a
                          href="https://www.linkedin.com/posts/laky-the-digital-key_entrevista-a-ruth-remolar-ceo-de-laky-en-activity-6791311074340802560-oG1O/"
                          target="_blank"
                        >
                          <img
                            src="assets/images/press/Logos-11.png"
                            class="logo-news"
                          />
                        </a>
                      </div>

                      <div class="row justify-content-center months">
                        abril 2021
                      </div>
                    </div>
                    <div
                      class="
                        col-lg-4 col-sm-6 col-xs-12
                        lg_pb_20
                        logo-news-container
                      "
                    >
                      <div class="row justify-content-center">
                        <a
                          href="https://academy.fynkus.com/es/un-caf%C3%A9-con-ruth-remolar-de-laky"
                          target="_blank"
                        >
                          <img
                            src="assets/images/press/Logos-09.png"
                            class="logo-news"
                          />
                        </a>
                      </div>

                      <div class="row justify-content-center months">
                        marzo 2021
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <a
                class="carousel-control-next carousel-news-control-next"
                href="#carouselNews"
                role="button"
                data-slide="next"
              >
                <span
                  class="carousel-control-next-icon"
                  aria-hidden="true"
                ></span>
                <span class="sr-only">Next</span>
              </a>
            </div>

            <div class="carousel-item">
              <a
                class="carousel-control-prev carousel-news-control-prev"
                href="#carouselNews"
                role="button"
                data-slide="prev"
              >
                <span
                  class="carousel-control-prev-icon"
                  aria-hidden="true"
                ></span>
                <span class="sr-only">Previous</span>
              </a>
              <div class="row justify-center lg_pt_50 white">
                <div class="col-lg-9 col-md-8 col-sm-8">
                  <div class="row justify-center">
                    <div
                      class="
                        col-lg-4 col-sm-6 col-xs-12
                        lg_pb_20
                        logo-news-container
                      "
                    >
                      <div class="row justify-content-center">
                        <a
                          href="https://lanzadera.es/infografia-emprendimiento-femenino-en-espana/"
                          target="_blank"
                        >
                          <img
                            src="assets/images/press/Logos-01.png"
                            class="logo-news"
                          />
                        </a>
                      </div>

                      <div class="row justify-content-center months">
                        marzo 2021
                      </div>
                    </div>
                    <div
                      class="
                        col-lg-4 col-sm-6 col-xs-12
                        lg_pb_20
                        logo-news-container
                      "
                    >
                      <div class="row justify-content-center">
                        <a
                          href="https://www.ivoox.com/laky-the-digital-key-audios-mp3_rf_66267023_1.html
                      "
                          target="_blank"
                        >
                          <img
                            src="assets/images/press/Logos-07.png"
                            class="logo-news"
                          />
                        </a>
                      </div>

                      <div class="row justify-content-center months">
                        marzo 2021
                      </div>
                    </div>
                    <div
                      class="
                        col-lg-4 col-sm-6 col-xs-12
                        lg_pb_20
                        logo-news-container
                      "
                    >
                      <div class="row justify-content-center">
                        <a
                          href="https://valenciaplaza.com/lanzadera-suma-a-100-nuevas-empresas-y-eleva-a-300-las-instaladas-en-marina-de-empresas"
                          target="_blank"
                        >
                          <img
                            src="assets/images/press/Logos-04.png"
                            class="logo-news"
                          />
                        </a>
                      </div>

                      <div class="row justify-content-center months">
                        enero 2021
                      </div>
                    </div>
                    <div
                      class="
                        col-lg-4 col-sm-6 col-xs-12
                        lg_pb_20
                        logo-news-container
                      "
                    >
                      <div class="row justify-content-center">
                        <a
                          href="https://www.businessinsider.es/lanzadera-selecciona-100-nuevas-startups-llega-300-sede-788487"
                          target="_blank"
                        >
                          <img
                            src="assets/images/press/Logos-05.png"
                            class="logo-news"
                          />
                        </a>
                      </div>

                      <div class="row justify-content-center months">
                        enero 2021
                      </div>
                    </div>
                    <div
                      class="
                        col-lg-4 col-sm-6 col-xs-12
                        lg_pb_20
                        logo-news-container
                      "
                    >
                      <div class="row justify-content-center">
                        <a
                          href="https://www.eleconomista.es/valenciana/noticias/10983657/01/21/Las-100-nuevas-empresas-de-la-aceleradora-de-Juan-Roig-de-cibermedicina-al-saxofon.html"
                          target="_blank"
                        >
                          <img
                            src="assets/images/press/Logos-02.png"
                            class="logo-news"
                          />
                        </a>
                      </div>

                      <div class="row justify-content-center months">
                        enero 2021
                      </div>
                    </div>
                    <div
                      class="
                        col-lg-4 col-sm-6 col-xs-12
                        lg_pb_20
                        logo-news-container
                      "
                    >
                      <div class="row justify-content-center">
                        <a
                          href="https://www.abc.es/espana/comunidad-valenciana/abci-estas-cien-nuevas-empresas-seleccionadas-este-lanzadera-juan-roig-202101111154_noticia.html"
                          target="_blank"
                        >
                          <img
                            src="assets/images/press/Logos-08.png"
                            class="logo-news"
                          />
                        </a>
                      </div>

                      <div class="row justify-content-center months">
                        enero 2021
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <a
                class="carousel-control-next carousel-news-control-next"
                href="#carouselNews"
                role="button"
                data-slide="next"
              >
                <span
                  class="carousel-control-next-icon"
                  aria-hidden="true"
                ></span>
                <span class="sr-only">Next</span>
              </a>
            </div>

            <div class="carousel-item">
              <a
                class="carousel-control-prev carousel-news-control-prev"
                href="#carouselNews"
                role="button"
                data-slide="prev"
              >
                <span
                  class="carousel-control-prev-icon"
                  aria-hidden="true"
                ></span>
                <span class="sr-only">Previous</span>
              </a>
              <div class="row justify-center lg_pt_50 white">
                <div class="col-lg-9 col-md-8 col-sm-8">
                  <div class="row justify-center">
                    <div
                      class="
                        col-lg-4 col-sm-6 col-xs-12
                        lg_pb_20
                        logo-news-container
                      "
                    >
                      <div class="row justify-content-center">
                        <a
                          href="https://www.eleconomista.es/valenciana/noticias/10983657/01/21/Las-100-nuevas-empresas-de-la-aceleradora-de-Juan-Roig-de-cibermedicina-al-saxofon.html"
                          target="_blank"
                        >
                          <img
                            src="assets/images/press/Logos-02.png"
                            class="logo-news"
                          />
                        </a>
                      </div>

                      <div class="row justify-content-center months">
                        enero 2021
                      </div>
                    </div>
                    <div
                      class="
                        col-lg-4 col-sm-6 col-xs-12
                        lg_pb_20
                        logo-news-container
                      "
                    >
                      <div class="row justify-content-center">
                        <a
                          href="https://www.larazon.es/comunidad-valenciana/20210111/4tv2vfpzlfe3vn4r4nbxt3zxsm.html"
                          target="_blank"
                        >
                          <img
                            src="assets/images/press/Logos-06.png"
                            class="logo-news"
                          />
                        </a>
                      </div>

                      <div class="row justify-content-center months">
                        enero 2021
                      </div>
                    </div>
                    <div
                      class="
                        col-lg-4 col-sm-6 col-xs-12
                        lg_pb_20
                        logo-news-container
                      "
                    >
                      <div class="row justify-content-center">
                        <a
                          href="https://www.expansion.com/valencia/2021/01/11/5ffc235a468aeb89508b4677.html"
                          target="_blank"
                        >
                          <img
                            src="assets/images/press/Logos-03.png"
                            class="logo-news"
                          />
                        </a>
                      </div>

                      <div class="row justify-content-center months">
                        enero 2021
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <a
                class="carousel-control-next carousel-news-control-next"
                href="#carouselNews"
                role="button"
                data-slide="next"
              >
                <span
                  class="carousel-control-next-icon"
                  aria-hidden="true"
                ></span>
                <span class="sr-only">Next</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="text_md_center text-center lg_pt_50">
      <a class="page-scroll" href="#team">
        <img src="assets/images/flecha.png" class="arrow" alt="flecha" />
      </a>
    </div>
  </div>
</section>
<!-- END SECTION NEWS -->

<!-- START SECTION TEAM -->
<section id="team" class="section_laky_equipo section_team">
  <div class="container">
    <div class="row">
      <div class="col-xl-3 col-lg-6 col-sm-12">
        <div class="bg-team">
          <img
            src="assets/images/team-2.png"
            class="team-circle"
            alt="team-2"
          />
          <span class="team-name"> Ruth Remolar </span>
          <span class="team-job text-upper"> CEO & Co-Founder </span>
          <span class="team-job">
            Licenciada en Ciencias Matemáticas por la UV. Ha desarrollado su
            trayectoria en el ámbito de la Consultoría y la Tecnología del
            sector Financiero.
          </span>
          <span class="team-job-small">
            Desarrollo de poyectos I+D, ha contribuido en proyectos de IA
            basados en sistemas cognitivos.
          </span>
          <span class="team-job-small">
            Actualmente vinculada al Grupo Santander desde la división de
            Consumer Finance.
          </span>
        </div>
      </div>
      <div class="col-xl-3 col-lg-6 col-sm-12">
        <div class="bg-team">
          <img
            src="assets/images/team-3.png"
            class="team-circle"
            alt="team-3"
          />
          <span class="team-name"> Alberto Rodríguez </span>
          <span class="team-job text-upper"> Co-Founder </span>
          <span class="team-job">
            Abogado y consultor. Master MBA en Gestión empresarial. Ha sido
            socio en startups como The Glue Concept, Emerita Legal o Dedalo
            salud.
          </span>
          <span class="team-job-small">
            Asesora a Startups como Goi Travel (Miembro del consejo de
            administración), Viv ibiza villas, Near gestión, Xoia Development
            software, Gooru Live…
          </span>
        </div>
      </div>
      <div class="col-xl-3 col-lg-6 col-sm-12">
        <div class="bg-team">
          <img
            src="assets/images/team-1.png"
            class="team-circle"
            alt="team-1"
          />
          <span class="team-name"> Álvaro Remolar </span>
          <span class="team-job text-upper"> CTO </span>
          <span class="team-job">
            Más de 30 años de experiencia en el Sector Industrial y Tecnológico.
            Su sólida trayectoria profesional ha sido consolidada en BP como
            Manager de Control Avanzado y Optimización.
          </span>
          <span class="team-job-small">
            Actualmente ejerce como consultor de servicios de Ingeniería y
            además es Asesor Senior en SECOT (Seniors Españoles para la
            Cooperación Técnica).
          </span>
        </div>
      </div>
      <div class="col-xl-3 col-lg-6 col-sm-12">
        <div class="bg-team">
          <img
            src="assets/images/team-4.png"
            class="team-circle"
            alt="team-4"
          />
          <span class="team-name">Sarai Remolar </span>
          <span class="team-job text-upper">CMO</span>
          <span class="team-job">
            Licenciada en Publicidad y Relaciones Públicas con una experiencia
            de más de 10 años en el sector de Marketing, Publicidad y
            Comunicación.
          </span>
          <span class="team-job-small">
            Durante su labor como Senior Account Manager ha desarrollado
            estrategias de comunicación y campañas de publicidad para clientes
            de ámbito Nacional e Internacional, como Bankia, Adolfo Domínguez,
            Mercedez-Benz y Grupo Mahou.
          </span>
        </div>
      </div>
    </div>
    <div class="text_md_center text-center">
      <a class="page-scroll" href="#partners">
        <img src="assets/images/flecha.png" class="arrow" alt="flecha" />
      </a>
    </div>
  </div>
</section>
<!-- END SECTION TEAM -->

<!-- START SECTION PARTNERS -->
<!-- <section id="partners" class="section_laky_partners">
  <div class="container">
    <div class="row justify-content-center">
      <h1 class="col-lg-12 text-center lg_pb_50">
        <strong class="logo-header">CLIENTES & PARTNERS</strong><br />
      </h1>

      <div class="col-lg-2 lg_pt_20">
        <div class="row justify-content-center">
          <a href="" target="_blank">
            <img class="logo-partners" src="assets/images/partners/Nayar-systems.png" />
          </a>
        </div>
      </div>

      <div class="col-lg-2 lg_pt_20">
        <div class="row justify-content-center">
          <a href="" target="_blank">
            <img class="logo-partners" src="assets/images/partners/GST.png" />
          </a>
        </div>
      </div>

      <div class="col-lg-2 lg_pt_20">
        <div class="row justify-content-center">
          <a href="" target="_blank">
            <img class="logo-partners" src="assets/images/partners/ColegioIngenierosCV.png" />
          </a>
        </div>
      </div>

      <div class="col-lg-2 lg_pt_20">
        <div class="row justify-content-center">
          <a href="" target="_blank">
            <img class="logo-partners" src="assets/images/partners/CTAC.png" />
          </a>
        </div>
      </div>

      <div class="col-lg-2 lg_pt_20">
        <div class="row justify-content-center">
          <a href="" target="_blank">
            <img class="logo-partners" src="assets/images/partners/Castlerock.png" />
          </a>
        </div>
      </div>

    </div>

    <div class="text_md_center text-center lg_pt_50">
      <a class="page-scroll" href="#contact">
        <img src="assets/images/flecha.png" class="arrow" alt="flecha" />
      </a>
    </div>
  </div>
</section> -->
<!-- END SECTION PARTNERS -->

<!-- START SECTION CONTACT -->
<section id="contact" class="section_laky_contacto">
  <div class="container">
    <div class="row justify-content-center">
      <h1 class="col-lg-12 text-center">
        <div class="row justify-content-center text-center pt-35 pb-20">
          <strong class="col-md-12 logo-header fs-25"
            >CONTACTA CON EL EQUIPO LAKY</strong
          ><br />
          <span class="col-lg-5 col-md-10 col-sm-10 contact-text-sub">
            Puedes contactar con nosotros en el email
            <a href="mailto:info@laky.es">info@laky.es</a>, en el teléfono 682
            826 893 o rellenando el siguiente formulario
          </span>
        </div>
      </h1>

      <div class="col-lg-4 col-sm-8 col-md-8 w-80pc">
        <form
          [formGroup]="formGroup"
          (ngSubmit)="onSubmit(formGroup.value)"
          class="field_form_s2 lh-15"
        >
          <div class="row">
            <div class="form-group col-md-12">
              <label for="name" class="form-labels text-upper">Nombre</label>
              <div class="border-bottom">
                <div class="icon-box">
                  <img
                    src="assets/images/icon-2.png"
                    class="icon-1-contact"
                    alt="icon-2"
                  />
                </div>
                <input
                  type="text"
                  required="required"
                  formControlName="name"
                  placeholder="Nombre"
                  id="name"
                  class="form-control"
                  name="name"
                />
              </div>
            </div>
            <div class="form-group col-md-12">
              <label for="email" class="form-labels text-upper">Mail</label>
              <div class="border-bottom">
                <div class="icon-box">
                  <img
                    src="assets/images/icon-3.png"
                    class="icon-3-contact"
                    alt="icon-3s"
                  />
                </div>
                <input
                  type="email"
                  name="_replyto"
                  formControlName="email"
                  required="required"
                  placeholder="Email"
                  id="email"
                  class="form-control"
                />
              </div>
            </div>
            <div class="form-group col-md-12">
              <label for="email" class="form-labels text-upper">Teléfono</label>
              <div class="border-bottom">
                <div class="icon-box">
                  <img
                    src="assets/images/icon-4.png"
                    class="icon-3-contact"
                    alt="icon-3s"
                  />
                </div>
                <input
                  type="text"
                  required="required"
                  placeholder="Teléfono"
                  id="phone"
                  formControlName="phone"
                  class="form-control"
                  name="phone"
                />
              </div>
            </div>
            <div class="form-group col-md-12">
              <label for="city" class="form-labels text-upper">Provincia</label>
              <div class="border-bottom">
                <div class="icon-box">
                  <img
                    src="assets/images/Icono-ciudad-03.png"
                    class="icon-3-contact"
                    alt="icon-ciudad"
                  />
                </div>
                <input
                  type="city"
                  required="required"
                  placeholder="Provincia"
                  id="city"
                  formControlName="city"
                  class="form-control"
                  name="city"
                />
              </div>
            </div>
            <div class="form-group col-md-12">
              <label for="message" class="form-labels text-upper">
                Mensaje
              </label>
              <div class="box-border">
                <textarea
                  required="required"
                  placeholder="Mensaje"
                  id="message"
                  formControlName="message"
                  class="form-control"
                  name="message"
                  rows="3"
                ></textarea>
              </div>
            </div>
            <div class="col-md-12 text-center">
              <button
                class="btn-contact width-100 mb-100-sm"
                type="submit"
                [disabled]="sendingEmail"
                *ngIf="!sended"
              >
                Enviar Mensaje
              </button>
              <button
                class="btn-contact width-100 mb-100-sm"
                type="submit"
                [disabled]="true"
                *ngIf="sended"
              >
                Enviado!
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>

    <div class="row justify-content-center pt-30">
      <div class="col-lg-2">
        <div class="btn_group flex">
          <a href="https://www.instagram.com/lakyaccess/" target="_blank">
            <img
              src="assets/images/instagram.svg"
              class="social-btn"
              alt="instagram"
            />
          </a>
          <a href="https://www.facebook.com/lakyaccess" target="_blank">
            <img
              src="assets/images/facebook.svg"
              class="social-btn"
              alt="facebook"
            />
          </a>
          <a
            href="https://www.youtube.com/channel/UCJMmc4JPqA3gL6-aMn2wrJw"
            target="_blank"
          >
            <img
              src="assets/images/youtube.png"
              class="social-btn"
              alt="youtube"
            />
          </a>
          <a
            href="https://www.linkedin.com/company/laky-the-digital-key/"
            target="_blank"
          >
            <img
              src="assets/images/linkedin.svg"
              class="social-btn"
              alt="linkedin"
            />
          </a>
        </div>
      </div>
    </div>

    <div class="row justify-content-center text-center pt-35">
      <span class="col-lg-6 text-center contact-text-sub">
        LAKY ha sido seleccionada entre más de 1.000 empresas para el programa
        Traction de la prestigiosa aceleradora de empresas LANZADERA
      </span>
    </div>

    <div class="row justify-content-center text-center pb-20">
      <img
        src="assets/images/lanzadera.png"
        width="300"
        height="100"
        class="justify-content-center text-center"
        alt="lanzadera"
      />
    </div>
  </div>
</section>
<!-- END SECTION CONTACT -->

<!-- Modal -->
<div
  class="modal fade"
  id="myModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-body">
        <a class="close-modal" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </a>
        <!-- 16:9 aspect ratio -->
        <div class="embed-responsive embed-responsive-16by9">
          <video controls>
            <source src="assets/videos/laky.mp4" type="video/mp4" id="video" />
          </video>
        </div>
      </div>
    </div>
  </div>
</div>
