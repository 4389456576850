<!-- START SECTION PRIVACY -->
<section id="start" class="section_banner section_laky_start_1">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-10 res_md_mt_50 res_sm_mt_20 pt-lg-30">
        <p style="text-align: center">
          <b
            ><u
              ><span lang="ES" class="p1 ft3"
                >POLITICA PRIVACIDAD READY2PARK</span
              ></u
            ></b
          >
        </p>
        <div id="page_1">
          <p class="p0 ft0">
            De conformidad con el Reglamento (UE) 2016/679, del Parlamento
            Europeo y del Consejo, de 27 de abril de 2016, relativo a la
            protección de las personas físicas en lo que respecta al tratamiento
            de datos personales y a la libre circulación de estos datos
            (Reglamento General de Protección de Datos - RGPD), READY2PARK
            informa a los usuarios de la aplicación, acerca del tratamiento de
            los datos personales, que ellos voluntariamente hayan facilitado
            durante el proceso de registro, acceso y utilización del servicio.
          </p>
          <p class="p1 ft3">
            <span class="ft1">1.</span
            ><span class="ft2"
              >IDENTIFICACIÓN DEL RESPONSABLE DEL TRATAMIENTO.</span
            >
          </p>
          <p class="p2 ft4">
            READY2PARK con CIF No: B88485032 y domicilio en Ronda de Segovia. 12
            10c 28005 (Madrid)a efectos de notificaciones e inscrita en el
            Registro Mercantil de Madrid Tomo 39687, Folio 91, Hoja M-705154,
            inscripción 1a (en adelante, el Responsable del Tratamiento), es la
            entidad responsable del tratamiento de los datos facilitados por los
            clientes de la Aplicación (en adelante, el/los Usuario/s).
          </p>
          <p class="p3 ft3">
            <span class="ft1">2.</span
            ><span class="ft2">FINALIDAD DEL TRATAMIENTO DE DATOS.</span>
          </p>
          <p class="p4 ft4">
            Para proceder al registro, acceso y posterior uso de la Aplicación,
            el Usuario deberá facilitar - de forma voluntaria-, datos de
            carácter personal consistentes en nombre, apellidos, documento
            nacional de identidad o NIE, dirección completa, teléfono de
            contacto y correo electrónico, los cuales serán incorporados a
            soportes automatizados titularidad de READY2PARK.
          </p>
          <p class="p4 ft4">
            La recogida, almacenamiento, modificación, estructuración y en su
            caso, eliminación, de los datos proporcionados por los Usuarios,
            constituirán operaciones de tratamiento llevadas a cabo por el
            Responsable, con la finalidad de garantizar el correcto
            funcionamiento de la Aplicación, mantener la relación de prestación
            de servicios y/o comercial con el Usuario, y para la gestión,
            administración, información, prestación y mejora del servicio.
          </p>
          <p class="p4 ft4">
            Los datos personales facilitados por el Usuario especialmente, el
            correo electrónico o e-mail se emplearán para únicamente con la
            finalidad para la que fueron cedidos, así como posibles
            comunicaciones comerciales de la Aplicación, siempre y cuando, el
            Usuario haya prestado previamente su consentimiento expreso para la
            recepción de estas comunicaciones vía electrónica.
          </p>
          <p class="p4 ft4">
            En todo caso, el usuario autoriza a READY2PARK a la utilización de
            los datos facilitados para uso interno del responsable para fines
            estadísticos y la realización de controles de depuración (debug).
          </p>
          <p class="p3 ft3">
            <span class="ft1">3.</span><span class="ft2">PERMISOS.</span>
          </p>
          <p class="p4 ft4">
            El Usuario autoriza a READY2PARK a utilizar el Bluetooth del
            dispositivo móvil y acceder a su geolocalización con el fin de abrir
            accesos autorizados y localizar su posición para ofrecer servicios
            compartidos por el resto de Usuarios.
          </p>
        </div>
        <div id="page_2">
          <div id="id2_1">
            <p class="p13 ft3">
              <span class="ft1">4.</span><span class="ft2">LEGITIMACIÓN.</span>
            </p>
            <p class="p9 ft3">
              El tratamiento de los datos del Usuario, se realiza con las
              siguientes bases jurídicas que legitiman el mismo:
            </p>
            <p class="p10 ft8">
              <span class="ft3">•</span
              ><span class="ft7"
                >La solicitud de información y/o la contratación de los
                servicios de la Aplicación, cuyos términos y condiciones se
                pondrán a disposición del Usuario en todo caso, con carácter
                previo, para su expresa aceptación.</span
              >
            </p>
            <p class="p11 ft8">
              <span class="ft3">•</span
              ><span class="ft7"
                >El consentimiento libre, específico, informado e inequívoco del
                Usuario, poniendo a su disposición la presente política de
                privacidad, que deberá aceptar mediante una declaración o una
                clara acción afirmativa, como el marcado de una casilla
                dispuesta al efecto. En caso de que el Usuario no facilite a
                READY2PARK sus datos, o lo haga de forma errónea o incompleta,
                no será posible proceder al uso de la Aplicación.</span
              >
            </p>
            <p class="p13 ft3">
              <span class="ft1">5.</span
              ><span class="ft2">CONSERVACIÓN DE LOS DATOS PERSONALES.</span>
            </p>
            <p class="p14 ft4">
              Los datos personales proporcionados por el Usuario, se conservarán
              en los sistemas y bases de datos del Responsable del Tratamiento,
              mientras aquél continúe haciendo uso de la Aplicación, y siempre
              que no solicite su supresión.
            </p>
            <p class="p15 ft3">
              Con el objetivo de depurar las posibles responsabilidades
              derivadas del tratamiento, los datos se conservarán por un período
              mínimo de cinco años.
            </p>
            <p class="p16 ft3">
              <span class="ft1">6.</span><span class="ft2">DESTINATARIOS.</span>
            </p>
            <p class="p17 ft9">
              Los datos no se comunicarán a ningún tercero ajeno a READY2PARK
              salvo obligación legal o en cualquier caso, previa solicitud del
              consentimiento del Usuario.
            </p>
            <p class="p18 ft10">
              De otra parte, READY2PARK podrá dar acceso o transmitir los datos
              personales facilitados por el Usuario, a terceros proveedores de
              servicios, con los que haya suscrito acuerdos de encargo de
              tratamiento de datos, y que únicamente accedan a dicha información
              para prestar un servicio en favor y por cuenta del Responsable.
            </p>
          </div>
        </div>
        <div id="page_3">
          <div id="id3_1">
            <p class="p13 ft3">
              <span class="ft1">7.</span
              ><span class="ft2">RETENCIÓN DE DATOS.</span>
            </p>
            <p class="p4 ft4">
              READY2PARK, informa al Usuario de que, como prestador de servicio
              de alojamiento de datos y en virtud de lo establecido en la Ley
              34/2002 de 11 de julio de Servicios de la Sociedad de la
              Información y de Comercio Electrónico (LSSI), retiene por un
              período máximo de 12 meses la información imprescindible para
              identificar el origen de los datos alojados y el momento en que se
              inició la prestación del servicio.
            </p>
            <p class="p19 ft4">
              La retención de estos datos no afecta al secreto de las
              comunicaciones y sólo podrán ser utilizados en el marco de una
              investigación criminal o para la salvaguardia de la seguridad
              pública, poniéndose a disposición de los jueces y/o tribunales o
              del Ministerio que así los requiera.
            </p>
            <p class="p20 ft9">
              La comunicación de datos a las Fuerzas y Cuerpos de Seguridad del
              Estado, se hará en virtud de lo dispuesto por la normativa sobre
              protección de datos personales, y bajo el máximo respeto a la
              misma.
            </p>
            <p class="p13 ft3">
              <span class="ft1">8.</span
              ><span class="ft2">PROTECCIÓN DE LA INFORMACIÓN ALOJADA.</span>
            </p>
            <p class="p4 ft4">
              El Responsable del Tratamiento, adopta las medidas necesarias para
              garantizar la seguridad, integridad y confidencialidad de los
              datos conforme a lo dispuesto en el Reglamento (UE) 2016/679 del
              Parlamento Europeo y del Consejo, de 27 de abril de 2016, relativo
              a la protección de las personas físicas en lo que respecta al
              tratamiento de datos personales y a la libre circulación de los
              mismos.
            </p>
            <p class="p18 ft5">
              Si bien el Responsable, realiza copias de seguridad de los
              contenidos alojados en sus servidores, sin embargo no se
              responsabiliza de la pérdida o el borrado accidental de los datos
              por parte de los Usuarios.
            </p>
            <p class="p21 ft0">
              De igual manera, no garantiza la reposición total de los datos
              borrados por los Usuarios, ya que los citados datos podrían haber
              sido suprimidos y/o modificados durante el periodo de tiempo
              transcurrido desde la última copia de seguridad.
            </p>
            <p class="p21 ft0">
              Los servicios facilitados o prestados a través de la Aplicación,
              excepto los servicios específicos de backup, no incluyen la
              reposición de los contenidos conservados en las copias de
              seguridad realizadas por el Responsable del Tratamiento, cuando
              esta pérdida sea imputable al usuario; en este caso, se
              determinará una tarifa acorde a la complejidad y volumen de la
              recuperación, siempre previa aceptación del usuario. La reposición
              de datos borrados sólo está incluida en el precio del servicio
              cuando la pérdida del contenido sea debida a causas atribuibles al
              Responsable.
            </p>
            <p class="p22 ft3">
              <span class="ft1">9.</span
              ><span class="ft2">EJERCICIO DE DERECHOS.</span>
            </p>
            <p class="p23 ft4">
              READY2PARK informa al Usuario de que le asisten los derechos de
              acceso, rectificación, limitación, supresión, oposición y
              portabilidad, los cuales podrá ejercitar mediante petición
              dirigida a la dirección Ronda de Segovia, 12 10C, Madrid (28005)
              Asimismo, el Usuario tiene derecho a revocar el consentimiento
              inicialmente prestado, y a interponer reclamaciones de derechos
              frente a la Agencia Española de Protección de Datos (AEPD).
            </p>
          </div>
        </div>
        <div id="page_4">
          <div id="id4_1">
            <p class="p13 ft3">
              <span class="ft1">10.</span
              ><span class="ft2">COMUNICACIONES POR VÍA ELECTRÓNICA.</span>
            </p>
            <p class="p4 ft4">
              En aplicación de la LSSI (Ley de Servicios de la Sociedad de la
              Información), READY2PARK, no enviará comunicaciones publicitarias
              o promocionales por correo electrónico u otro medio de
              comunicación electrónica equivalente que previamente no hubieran
              sido solicitadas o expresamente autorizadas por los destinatarios
              de las mismas.
            </p>
            <p class="p25 ft5">
              En el caso de usuarios con los que exista una relación
              contractual, jurídica o de servicios previa, el Responsable del
              Tratamiento, sí está autorizado al envío de comunicaciones
              comerciales referentes a productos o servicios del Responsable que
              sean similares a los que inicialmente fueron objeto de
              contratación con el cliente.
            </p>
            <p class="p26 ft9">
              En caso de que el Usuario quiera darse de baja a la hora de
              recibir las citadas comunicaciones, podrá hacerlo dirigiéndose a
              Ronda de Segovia, 12 10C, Madrid (28005).
            </p>
          </div>
        </div>

        <div id="page_1">
          <div id="id1_1">
            <p class="p0 ft0">Política de Privacidad</p>
            <p class="p1 ft4">
              Este documento recoge la política de protección de datos de
              carácter personal que cada usuario entrega y que LAKY recoge, usa
              y mantiene a través de los productos contratados con nuestra
              aplicación o servicio web
              <a href="http://www.laky.es/"
                ><span class="ft1">www.laky.es</span></a
              ><a href="http://www.laky.es/"><span class="ft2">.</span></a> Esta
              política afecta a todos aquellos datos recogidos tanto a través de
              nuestra web como a través de la aplicación y/o terceros a los que
              ha autorizado a entregar datos a través de nuestra plataforma. Al
              visitar
              <a href="http://www.laky.es/"
                ><span class="ft3">www.laky.es</span></a
              >
              usted está aceptando y consintiendo las prácticas descritas en
              esta política. Puede acceder a la política de privacidad en
              cualquier momento dentro de nuestra web a través del espacio
              dedicado a ello.
            </p>
            <p class="p2 ft4">
              A los efectos de la Ley de Protección de Datos de 2018 y el RGPD
              (la “Ley”), el responsable del tratamiento es READY2PARK S.L.
              B88485032 Ronda de Segovia 12 10C 28005 MADRID.
            </p>
            <p class="p3 ft0">La información</p>
            <p class="p4 ft4">
              La información de identificación personal de los usuarios se
              recopila a través de nuestra web o de la APP, mediante la visita y
              la aceptación de los términos y condiciones de la misma. Así Usted
              cederá los datos de acceso cuando acceda a nuestro site web y
              acepte las condiciones de Uso del mismo, en donde existe un
              formulario con los datos de usuario que Usted entrega, incluyendo
              teléfono, correo electrónico o datos personales. La información
              que a los usuarios se les puede pedir incluyen su nombre,
              dirección de correo electrónico, dirección postal, número de
              teléfono, información financiera y tarjeta de crédito. Esta
              información se mantiene hasta que usted cierre su cuenta.
              Cualquier usuario puede negarse a entregar sus datos personales,
              salvo en el caso de que contrate servicios en a través de nuestra
              web, donde se recogen los datos imprescindibles la para la
              transacción.
            </p>
            <p class="p5 ft4">
              Asimismo, se recoge información de identificación no personal
              sobre los usuarios cuando interactúan con nuestro sitio.
              Información de identificación no personal puede incluir:
            </p>
            <p class="p6 ft4">
              <span class="ft4">∙</span
              ><span class="ft5"
                >Información técnica, incluyendo el protocolo de Internet (IP)
                utilizada para conectar su ordenador a Internet, la información
                de acceso, tipo de navegador y versión, configuración de zona
                horaria, tipos de </span
              >plug-in en el navegador y versiones, sistema operativo y la
              plataforma; y
            </p>
            <p class="p6 ft4">
              <span class="ft4">∙</span
              ><span class="ft5"
                >Información acerca de su visita, incluyendo los localizadores
                de uniforme de recursos (URL) de clic a través y desde nuestro
                sitio (incluyendo la fecha y hora), los productos que ha visto o
                buscado, los tiempos de respuesta de la página, errores de
                descarga, la duración de las visitas a determinadas páginas,
                información de la interacción de página (como el desplazamiento,
                clics, y el ratón en off), y los métodos utilizados para navegar
                fuera de la página y cualquier número de teléfono utilizado para
                llamar a nuestro número desde servicio al cliente.</span
              >
            </p>
            <p class="p3 ft0">
              Esta información se mantiene durante un período de tiempo
              razonable para uso interno.
            </p>
            <p class="p3 ft0">Información de terceros</p>
            <p class="p9 ft4">
              Trabajamos en colaboración con terceros (servicios de pago,
              publicidad, análisis, búsqueda de proveedores, agencias de
              referencia de crédito) y podríamos recibir información de
              identificación personal sobre usted de ellos.
            </p>
            <p class="p1 ft4">
              Cuando realice una transacción comercial o se suscriba a los
              servicios de LAKY, Usted envía la información de datos financieros
              para permitir el procesamiento de los pagos (según el método de
              pago, por ejemplo, un número de tarjeta de crédito). Su pago se
              procesará a través del proveedor de pago externo Stripe, 185 Berry
              Street # 550, San Francisco, CA 94107, EE. UU. Y Paypal, 2211
              North First Street, San José, CA 95131, EE. UU.
            </p>
            <p class="p3 ft0">Tratamiento de la información</p>
            <p class="p11 ft4">
              Los datos personales recabados a través del sitio web se utilizan
              para fines comerciales, de marketing, de gestión de pedidos y
              encuestas de satisfacción de usuario.
            </p>
            <p class="p12 ft4">
              LAKY puede utilizar la información de identificación personal y la
              no personal de un usuario para personalizar la experiencia del
              usuario, para evaluar el impacto publicitario; para procesar los
              pagos que
            </p>
          </div>
        </div>
        <div id="page_2">
          <div id="id2_1">
            <p class="p13 ft4">
              usted ha aceptado; para validar los accesos a nuestro producto;
              para recibir información sobre la evolución del producto o
              boletines de suscripción.
            </p>
            <p class="p3 ft0">Protección de sus datos</p>
            <p class="p15 ft7">
              Desde LAkY no compartimos con terceros información de nuestros
              usuarios, y hacemos lo posible para garantizar que los accesos a
              la misma sean restringidos por parte de nuestro personal.
            </p>
            <p class="p16 ft8">
              Así, y a pesar de que la protección de datos en Internet nunca es
              fiable al cien por cien, protegemos nuestro sistema con accesos
              cifrados y canales de comunicación seguros. Para proteger la
              información sensible y privada que incluye el intercambio de datos
              entre el sitio y sus usuarios pasa por un canal de comunicación
              SSL segura y cifrada y protegida con firmas digitales.
            </p>
            <p class="p12 ft4">
              Si le hemos dado (o donde se haya elegido) una contraseña que le
              permite acceder a ciertas partes de nuestro sitio, usted es
              responsable de mantener su confidencialidad. Les pedimos que no
              comparta la contraseña con nadie.
            </p>
            <p class="p3 ft0">Permisos</p>
            <p class="p1 ft4">
              Este documento recoge la política de protección de datos de
              carácter personal que cada usuario entrega y que LAKY recoge, usa
              y mantiene a través de los productos contratados con nuestra
              aplicación o servicio web
              <a href="http://www.laky.es/"
                ><span class="ft1">www.laky.es</span></a
              ><a href="http://www.laky.es/"><span class="ft2">.</span></a> Esta
              política afecta a todos aquellos datos recogidos tanto a través de
              nuestra web como a través de la aplicación y/o terceros a los que
              ha autorizado a entregar datos a través de nuestra plataforma. Al
              visitar
              <a href="http://www.laky.es/"
                ><span class="ft3">www.laky.es</span></a
              >
              usted está aceptando y consintiendo las prácticas descritas en
              esta política. Puede acceder a la política de privacidad en
              cualquier momento dentro de nuestra web a través del espacio
              dedicado a ello.
            </p>
            <p class="p18 ft4">
              A los efectos de la Ley de Protección de Datos de 2018 y el RGPD
              (la “Ley”), el responsable del tratamiento es READY2PARK S.L.
              B88485032 Ronda de Segovia 12 10C 28005 MADRID.
            </p>
            <p class="p3 ft0">La información</p>
            <p class="p20 ft4">
              La información de identificación personal de los usuarios se
              recopila a través de nuestra web o a través de la APP, mediante la
              visita y la aceptación de los términos y condiciones de la misma.
              Así Usted cederá los datos de acceso cuando acceda a nuestro site
              web y acepte las condiciones de Uso del mismo, en donde existe un
              formulario con los datos de usuario que Usted entrega, incluyendo
              teléfono, correo electrónico o datos personales. La información
              que a los usuarios se les puede pedir incluyen su nombre,
              dirección de correo electrónico, dirección postal, número de
              teléfono, información financiera y tarjeta de crédito. Esta
              información se mantiene hasta que usted cierre su cuenta.
              Cualquier usuario puede negarse a entregar sus datos personales,
              salvo en el caso de que contrate servicios en a través de nuestra
              web, donde se recogen los datos imprescindibles la para la
              transacción.
            </p>

            <p class="p22 ft4">
              Asimismo, se recoge información de identificación no personal
              sobre los usuarios cuando interactúan con nuestro sitio.
              Información de identificación no personal puede incluir:
            </p>
            <p class="p23 ft4">
              <span class="ft4">∙</span
              ><span class="ft5"
                >Información técnica, incluyendo el protocolo de Internet (IP)
                utilizada para conectar su ordenador a Internet, la información
                de acceso, tipo de navegador y versión, configuración de zona
                horaria, tipos de </span
              >plug-in en el navegador y versiones, sistema operativo y la
              plataforma; y
            </p>
            <p class="p23 ft4">
              <span class="ft4">∙</span
              ><span class="ft5"
                >Información acerca de su visita, incluyendo los localizadores
                de uniforme de recursos (URL) de clic a través y desde nuestro
                sitio (incluyendo la fecha y hora), los productos que ha visto o
                buscado, los tiempos de respuesta de la página, errores de
                descarga, la duración de las visitas a determinadas páginas,
                información de la interacción de página (como el desplazamiento,
                clics, y el ratón en off), y los métodos utilizados para navegar
                fuera de la página y cualquier número de teléfono utilizado para
                llamar a nuestro número desde servicio al cliente.</span
              >
            </p>
            <p class="p8 ft4">
              Esta información se mantiene durante un período de tiempo
              razonable para uso interno.
            </p>
            <p class="p3 ft0">Información de terceros</p>
          </div>
        </div>
        <div id="page_3">
          <div id="id3_1">
            <p class="p20 ft4">
              Trabajamos en colaboración con terceros (servicios de pago,
              publicidad, análisis, búsqueda de proveedores, agencias de
              referencia de crédito) y podríamos recibir información de
              identificación personal sobre usted de ellos.
            </p>
            <p class="p24 ft4">
              Cuando realice una transacción comercial o se suscriba a los
              servicios de LAKY, Usted envía la información de datos financieros
              para permitir el procesamiento de los pagos (según el método de
              pago, por ejemplo, un número de tarjeta de crédito). Su pago se
              procesará a través del proveedor de pago externo Stripe, 185 Berry
              Street # 550, San Francisco, CA 94107, EE. UU. Y Paypal, 2211
              North First Street, SanJosé, CA95131, EE.UU.
            </p>

            <p class="p3 ft0">Tratamiento de la información</p>
            <p class="p26 ft4">
              Los datos personales recabados a través del sitio web se utilizan
              para fines comerciales, de marketing, degestión de pedidos y
              encuestas de satisfacción de usuario.
            </p>
            <p class="p9 ft4">
              LAKY puede utilizar la información de identificación personal y la
              no personal de un usuario para personalizar la experiencia del
              usuario, para evaluar el impacto publicitario; para procesar los
              pagos que usted ha aceptado; para validar los accesos a nuestro
              producto; para recibir información sobre la evolución del producto
              o boletines de suscripción.
            </p>
            <p class="p27 ft0">Permisos</p>
            <p class="p28 ft8">
              Los datos obtenidos mediante el acceso al Bluetooth de su
              dispositivo móvil y su geolocalización serán utilizados por LAKY
              con la finalidad de abrir accesos autorizados y localizar su
              posición para ofrecer servicios compartidos por el resto de
              Usuarios.
            </p>
            <p class="p29 ft0">Protección de sus datos</p>
            <p class="p28 ft7">
              Desde LAkY no compartimos con terceros información de nuestros
              usuarios, y hacemos lo posible para garantizar que los accesos a
              la misma sean restringidos por parte de nuestro personal.
            </p>
            <p class="p18 ft8">
              Así, y a pesar de que la protección de datos en Internet nunca es
              fiable al cien por cien, protegemos nuestro sistema con accesos
              cifrados y canales de comunicación seguros. Para proteger la
              información sensible y privada que incluye el intercambio de datos
              entre el sitio y sus usuarios pasa por un canal de comunicación
              SSL segura y cifrada y protegida con firmas digitales.
            </p>
            <p class="p18 ft8">
              Si le hemos dado (o donde se haya elegido) una contraseña que le
              permite acceder a ciertas partes de nuestro sitio, usted es
              responsable de mantener su confidencialidad. Les pedimos que no
              comparta la contraseña con nadie.
            </p>
            <p class="p29 ft0">Sus datos está seguros con nosotros</p>
            <p class="p30 ft4">
              Laky no comercia con los datos de sus clientes, ni los cede a
              terceros, ni gratuita, ni onerosamente. Cualquier dato que se
              quiera ceder a un tercero a fines publicitarios, de auditoría o de
              análisis, requerirá un consentimiento previo por su parte como
              cliente.
            </p>
            <p class="p12 ft4">
              Sus datos solo serán tratados fuera de la UE en caso de contacto
              con servidores de datos ubicado en terceros países que cuenten con
              niveles de seguridad aceptables y de primer nivel. Asimismo, el
              procesamiento de pagos puede situarse en un país externo de la UE.
              Al solicitar sus datos, Usted ha sido informado de esta
              circunstancia y acepta que estos datos se compartan con terceros
              proveedores de servicios de pago y gestión de datos, únicamente a
              efectos de los servicios contratados en la aplicación. Tomaremos
              todas las medidas razonablemente necesarias para garantizar que
              sus datos son tratados de forma segura y de acuerdo con esta
              política de privacidad.
            </p>
            <p class="p4 ft4">
              En caso de venta a tercero, su información podrá ser traspasada a
              un tercero, en cuyo caso se lo ofrecerá la posibilidad de
              rectificación o cancelación de datos y/o suscripción de producto.
            </p>
            <p class="p12 ft4">
              Si nos vemos en la obligación de revelar o compartir sus datos
              personales con el fin de cumplir con un requerimiento judicial,
              Usted será advertido ello, salvo que el mandato judicial requiera
              lo contrario.
            </p>
            <p class="p22 ft4">
              Laky es referenciada en sitios web de terceros a través de enlaces
              de publicidad o información. Laky no se hace responsable de los
              contenidos de webs de terceros donde se referencie ni de su
              contenido.
            </p>
          </div>
        </div>
        <div id="page_4">
          <p class="p0 ft0">Cancelación</p>
          <p class="p31 ft4">
            Usted puede solicitar que no procesemos sus datos personales con
            fines de marketing. Únicamente utilizaremos sus datos si Usted lo ha
            consentido de forma expresa en nuestro formulario de inscripción.
            Usted podrá rectificar o cancelar sus datos mediante el envío de un
            correo electrónico a la dirección
            <a href="mailto:datos@laky.es"
              ><span class="ft1">datos@laky.es</span></a
            >
            o por correo postal a la dirección READY2PARK S.L. B88485032 Ronda
            de Segovia 12 10C 28005 MADRID.
          </p>
          <p class="p18 ft4">
            Hay datos que por su naturaleza nos son requeridos para justificar
            la relación comercial (Agencia tributaria) y por lo tanto debemos
            conservar a pesar de que nos retire su consentimiento. Solamente
            conservaremos su información, mientras exista una relación
            contractual o comercial con nosotros. O bien mientras Vd. no ejerza
            ninguno de los derechos que hagan que no podamos conservar sus
            datos.
          </p>
          <p class="p12 ft4">
            Cualquier cambio en la política de privacidad de LAKY será
            comunicada por correo electrónico y publicada en la web de Lacky y
            solo se considerará aceptada si usted lo consiente de forma expresa
          </p>
          <p class="p22 ft4">
            Usted puede solicitar sus datos o bien acceder, rectificar,
            cancelar, suprimir, expresar limitaciones o reservas sobre sus datos
            en la dirección
            <a href="mailto:datos@laky.es"
              ><span class="ft3">datos@laky.es</span></a
            >
          </p>
          <p class="p14 ft0">Cómo ponerse en contacto con nosotros</p>
          <p class="p12 ft4">
            Si usted tiene alguna pregunta sobre esta Política de Privacidad,
            las prácticas de este sitio, o sus relaciones con este sitio, por
            favor, póngase en contacto con nosotros en READY2PARK S.L. B88485032
            Ronda de Segovia 12 10C 28005 MADRID.
          </p>
          <p class="p32 ft0">Sus datos está seguros con nosotros</p>
          <p class="p13 ft4">
            Laky no comercia con los datos de sus clientes, ni los cede a
            terceros, ni gratuita, ni onerosamente. Cualquier dato que se quiera
            ceder a un tercero a fines publicitarios, de auditoría o de
            análisis, requerirá un consentimiento previo por su parte como
            cliente.
          </p>
          <p class="p12 ft4">
            Sus datos solo serán tratados fuera de la UE en caso de contacto con
            servidores de datos ubicado en terceros países que cuenten con
            niveles de seguridad aceptables y de primer nivel. Asimismo, el
            procesamiento de pagos puede situarse en un país externo de la UE.
            Al solicitar sus datos, Usted ha sido informado de esta
            circunstancia y acepta que estos datos se compartan con terceros
            proveedores de servicios de pago y gestión de datos, únicamente a
            efectos de los servicios contratados en la aplicación. Tomaremos
            todas las medidas razonablemente necesarias para garantizar que sus
            datos son tratados de forma segura y de acuerdo con esta política de
            privacidad.
          </p>
          <p class="p4 ft4">
            En caso de venta a tercero, su información podrá ser traspasada a un
            tercero, en cuyo caso se lo ofrecerá la posibilidad de rectificación
            o cancelación de datos y/o suscripción de producto.
          </p>
          <p class="p12 ft4">
            Si nos vemos en la obligación de revelar o compartir sus datos
            personales con el fin de cumplir con un requerimiento judicial,
            Usted será advertido ello, salvo que el mandato judicial requiera lo
            contrario.
          </p>
          <p class="p22 ft4">
            Laky es referenciada en sitios web de terceros a través de enlaces
            de publicidad o información. Laky no se hace responsable de los
            contenidos de webs de terceros donde se referencie ni de su
            contenido.
          </p>
          <p class="p3 ft0">Cancelación</p>
          <p class="p33 ft4">
            Usted puede solicitar que no procesemos sus datos personales con
            fines de marketing. Únicamente utilizaremos sus datos si Usted lo ha
            consentido de forma expresa en nuestro formulario de inscripción.
            Usted podrá rectificar o cancelar sus datos mediante el envío de un
            correo electrónico a la dirección
            <a href="mailto:datos@laky.es"
              ><span class="ft1">datos@laky.es</span></a
            >
            o por correo postal a la dirección READY2PARK S.L. B88485032 Ronda
            de Segovia 12 10C 28005 MADRID.
          </p>
          <p class="p34 ft4">
            Hay datos que por su naturaleza nos son requeridos para justificar
            la relación comercial (Agencia tributaria) y por lo tanto debemos
            conservar a pesar de que nos retire su consentimiento. Solamente
            conservaremos su información, mientras exista una relación
            contractual o comercial con nosotros. O bien mientras Vd. no ejerza
            ninguno de los derechos que hagan que no podamos conservar sus
            datos.
          </p>
        </div>
        <div id="page_5">
          <div id="id5_1">
            <p class="p12 ft4">
              Cualquier cambio en la política de privacidad de LAKY será
              comunicada por correo electrónico y publicada en la web de Lacky y
              solo se considerará aceptada si usted lo consiente de forma
              expresa
            </p>
            <p class="p22 ft4">
              Usted puede solicitar sus datos o bien acceder, rectificar,
              cancelar, suprimir, expresar limitaciones o reservas sobre sus
              datos en la dirección
              <a href="mailto:datos@laky.es"
                ><span class="ft3">datos@laky.es</span></a
              >
            </p>
            <p class="p3 ft0">Cómo ponerse en contacto con nosotros</p>
            <p class="p20 ft4">
              Si usted tiene alguna pregunta sobre esta Política de Privacidad,
              las prácticas de este sitio, o sus relaciones con este sitio, por
              favor, póngase en contacto con nosotros en READY2PARK S.L.
              B88485032 Ronda de Segovia 12 10C 28005 MADRID.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- END SECTION PRIVACY -->
